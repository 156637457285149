import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../styles/main.css';
import PosterTextBlock from './PosterTextBlock';
import PosterBus from './PosterBus'


export default function PosterDetails() {
   const [posterFound, setPosterFound] = useState("");
   const [loading, setLoading] = useState(false);

   let { identifier } = useParams();

   const [modeArtwork, setModeArtwork] = useState(true);

   useEffect(() => {
      let isSubscribed = true;
      const getPoster = async () => {
         const getPostersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/poster/" + identifier);

         if (isSubscribed) {
            if (getPostersRes.data.artworkWidthCm && getPostersRes.data.artworkHeightCm) {
               if (getPostersRes.data.artworkWidthCm > getPostersRes.data.artworkHeightCm) {
                  if (getPostersRes.data.artworkWidthCm / getPostersRes.data.artworkHeightCm < 1.25) {
                     getPostersRes.data.orientation = "square"
                  } else {
                     getPostersRes.data.orientation = "horizontal"
                  }
               } else if (getPostersRes.data.artworkWidthCm < getPostersRes.data.artworkHeightCm) {
                  if (getPostersRes.data.artworkWidthCm / getPostersRes.data.artworkHeightCm > 0.8) {
                     getPostersRes.data.orientation = "square"
                  } else {
                     getPostersRes.data.orientation = "vertical"
                  }
               }
            }
            setPosterFound(getPostersRes.data);

            setLoading(true)
         }
      };
      getPoster();
      return () => isSubscribed = false
   }, []);

   // Get div center on the actual mobile browser viewport height
   const [mobileScreenHeight, setMobileScreenHeight] = useState(700)

   useEffect(() => {
      setMobileScreenHeight(window.innerHeight)
   }, []);


   return (
      <>
         {(!loading) ? <div className="w-full h-screen bg-white"></div> :

            <div className="flex-center">

               {/* ----------- BIG SCREENS VERSION ----------- */}
               {(window.innerWidth > 640) ?
                  <div id="busdivBIG" className="h-screen flex-center w-full">
                     <div className="h-full flex-col-center w-7/12" onClick={() => setModeArtwork(!modeArtwork)} >
                        <PosterBus poster={posterFound} busWidth="30" />
                     </div>

                     <div className="h-full flex justify-center items-center w-5/12">
                        <PosterTextBlock poster={posterFound} width={370} />
                     </div>

                  </div>

                  :

                  <div >
                     {/* ----------- SMALL SCREENS VERSION ----------- */}
                     <div className="flex-grow-0 flex-shrink-0 w-screen flex flex-col justify-start items-center" style={{ height: 0.88 * mobileScreenHeight, marginTop: 0.12 * mobileScreenHeight }}>

                        <div className="px-4 py-4 h-2/5 w-full flex flex-center" >
                           <PosterBus poster={posterFound} busWidth={mobileScreenHeight / 100 * 2.5} />
                        </div>

                        <div className="h-1/2 w-full whitespace-no-wrap" style={{ maxWidth: "100vw" }} >
                           <PosterTextBlock poster={posterFound} width={window.innerWidth / 1.8} />
                        </div>


                     </div>
                  </div>}

            </div>}
      </>
   );
}





/**
 import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../styles/main.css';
import PosterTextBlock from './PosterTextBlock';
import PosterBus from './utils/PosterBus'


export default function PosterDetails() {
   const [posterFound, setPosterFound] = useState("");
   const [loading, setLoading] = useState(false);

   let { identifier } = useParams();

   const [modeArtwork, setModeArtwork] = useState(true);

   useEffect(() => {
      let isSubscribed = true;
      const getPoster = async () => {
         const getPostersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/poster/" + identifier);

         if (isSubscribed) {
            if (getPostersRes.data.artworkWidthCm && getPostersRes.data.artworkHeightCm) {
               if (getPostersRes.data.artworkWidthCm > getPostersRes.data.artworkHeightCm) {
                  if (getPostersRes.data.artworkWidthCm / getPostersRes.data.artworkHeightCm < 1.25) {
                     getPostersRes.data.orientation = "square"
                  } else {
                     getPostersRes.data.orientation = "horizontal"
                  }
               } else if (getPostersRes.data.artworkWidthCm < getPostersRes.data.artworkHeightCm) {
                  if (getPostersRes.data.artworkWidthCm / getPostersRes.data.artworkHeightCm > 0.8) {
                     getPostersRes.data.orientation = "square"
                  } else {
                     getPostersRes.data.orientation = "vertical"
                  }
               }
            }
            setPosterFound(getPostersRes.data);
            setLoading(true)
         }
      };

      getPoster();
      return () => isSubscribed = false
   }, []);


   return (
      <>
         {(!loading) ? <div className="w-full h-screen bg-white"></div> :
            <div className="flex flex-col w-full h-screen justify-start items-center">

               {modeArtwork ? <div className="flex-center h-32">
                  <div className="h-full flex-col-center  opacity-40" onClick={() => setModeArtwork(!modeArtwork)} >
                     <PosterBus poster={posterFound} busWidth="4" />
                  </div>

                  <div className="w-4"></div>

                  <img src={"https://gasparddeshusses.com/images/artworks/" + posterFound.artworkIdentifier + ".jpg"} alt={posterFound.artworkIdentifier} className="h-10 " />
               </div> :
                  <div className="flex-center h-32">
                     <div className="h-full flex-col-center" >
                        <PosterBus poster={posterFound} busWidth="4" />
                     </div>

                     <div className="w-4"></div>

                     <img src={"https://gasparddeshusses.com/images/artworks/" + posterFound.artworkIdentifier + ".jpg"} alt={posterFound.artworkIdentifier} className="h-10   opacity-40" onClick={() => setModeArtwork(!modeArtwork)} />
                  </div>}

               {modeArtwork ?
                  <div id="artworkdiv" className="flex flex-col md:flex-row justify-center items-center w-full md:h-full pb-12" >
                     <img src={"https://gasparddeshusses.com/images/artworks/" + posterFound.artworkIdentifier + ".jpg"} alt={posterFound.artworkIdentifier} className="object-contain px-8 md:px-0 md:h-112 md:max-w-3xl" onClick={() => setModeArtwork(!modeArtwork)} />

                     <div className="h-8 md:h-0"></div>

                     {(window.innerWidth < 500) && <div className="h-full flex-col-center md:pl-16">
                        <PosterTextBlock poster={posterFound} width={window.innerWidth - 80} />
                     </div>}

                     {(window.innerWidth >= 500) && <div className="h-full flex-col-center md:pl-16">
                        <PosterTextBlock poster={posterFound} width="400" />
                     </div>}

                  </div> :
                  <div id="busdiv" className="flex flex-col md:flex-row justify-center items-center w-full md:h-full pb-12">
                     {(window.innerWidth < 500) && <div className="pt-4 h-full flex-col-center" onClick={() => setModeArtwork(!modeArtwork)} >
                        <PosterBus poster={posterFound} busWidth="20" />
                        <p className="w-full text-xs text-right pt-4">*truck not included</p>

                     </div>}

                     {(window.innerWidth >= 500) && <div className="h-full flex-col-center" onClick={() => setModeArtwork(!modeArtwork)} >
                        <PosterBus poster={posterFound} busWidth="40" />
                        <p className="w-full text-xs text-right pt-4 pr-8">*truck not included</p>

                     </div>}

                     <div className="h-full hidden md:flex flex-col-center md:pl-16">
                        <PosterTextBlock poster={posterFound} width="400" />
                     </div>
                  </div>}



            </div>}
      </>
   );
}

 */