
import createDataContext from "./createDataContext";

const defaultState = {
   artworks: [],
   lastArtwork: null
}

const userReducer = (state, action) => {
   switch (action.type) {
      case 'UPDATE_LAST_SEEN_ARTWORK':
         return { ...state, lastArtwork: action.payload }
      case 'UPDATE_ARTWORKS':
         return { ...state, artworks: action.payload }
      default:
         return state;
   }
}

const updateLastSeenArtwork = dispatch => async (lastArtwork) => {
   dispatch({ type: 'UPDATE_LAST_SEEN_ARTWORK', payload: lastArtwork })
}

const updateArtworks = dispatch => async (artworks) => {
   dispatch({ type: 'UPDATE_ARTWORKS', payload: artworks })
}


export const { Provider, Context } = createDataContext(
   userReducer,
   {
      updateLastSeenArtwork,
      updateArtworks
   },
   defaultState
)

