import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../styles/main.css';
import StickerTextBlock from './StickerTextBlock';
import StickerSkateboard from './StickerSkateboard'


export default function StickerDetails() {
   const [stickerFound, setStickerFound] = useState("");
   const [loading, setLoading] = useState(false);

   let { identifier } = useParams();

   const [modeArtwork, setModeArtwork] = useState(true);

   useEffect(() => {
      let isSubscribed = true;
      const getSticker = async () => {
         const getStickersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/sticker/" + identifier);

         if (isSubscribed) {
            if (getStickersRes.data.artworkWidthCm && getStickersRes.data.artworkHeightCm) {
               if (getStickersRes.data.artworkWidthCm >= getStickersRes.data.artworkHeightCm) {
                  if (getStickersRes.data.artworkWidthCm / getStickersRes.data.artworkHeightCm < 1.25) {
                     getStickersRes.data.orientation = "square_horizontal"
                  } else {
                     getStickersRes.data.orientation = "horizontal"
                  }
               } else if (getStickersRes.data.artworkWidthCm < getStickersRes.data.artworkHeightCm) {
                  if (getStickersRes.data.artworkWidthCm / getStickersRes.data.artworkHeightCm > 0.8) {
                     getStickersRes.data.orientation = "square_vertical"
                  } else {
                     getStickersRes.data.orientation = "vertical"
                  }
               }
            }
            setStickerFound(getStickersRes.data);

            setLoading(true)
         }
      };
      getSticker();
      return () => isSubscribed = false
   }, []);

   // Get div center on the actual mobile browser viewport height
   const [mobileScreenHeight, setMobileScreenHeight] = useState(700)

   useEffect(() => {
      setMobileScreenHeight(window.innerHeight)
   }, []);


   return (
      <>
         {(!loading) ? <div className="w-full h-screen bg-white"></div> :

            <div className="flex-col-center">

               {(window.innerWidth > 640) ?
                  <div className="flex-center w-full h-screen">
                     {/* ----------- BIG SCREENS VERSION ----------- */}

                     <div className="flex-col-center w-7/12" onClick={() => setModeArtwork(!modeArtwork)} >
                        <StickerSkateboard sticker={stickerFound} skateWidth="8" />
                     </div>


                     <div className="w-5/12 flex justify-center items-center">
                        <StickerTextBlock sticker={stickerFound} width={370} />
                     </div>
                  </div>

                  :

                  <div className="flex w-full" >
                     {/* ----------- SMALL SCREENS VERSION ----------- */}

                     <div className="flex-grow-0 flex-shrink-0 w-screen flex flex-col justify-start items-center" style={{ height: 0.88 * mobileScreenHeight, marginTop: 0.12 * mobileScreenHeight }}>

                        <div className="px-4 py-4 h-2/5 w-full flex flex-center" >
                           <StickerSkateboard sticker={stickerFound} skateWidth={mobileScreenHeight / 100 * 2.5} />
                        </div>

                        <div className="h-1/2 w-full whitespace-no-wrap" style={{ maxWidth: "100vw" }} >
                           <StickerTextBlock sticker={stickerFound} width={window.innerWidth / 1.8} />
                        </div>


                     </div>

                  </div>}

            </div>}
      </>
   );
}

/*
<div className="w-full bg-white z-10 h-screen flex items-center justify-center">
            <div className="h-96 w-160 border border-black"></div>
            <div className="h-96 w-104 flex flex-col justify-center items-end text-4xl">
               <span className="h-12">___________________</span>
               <span className="h-16">___________________</span>
               <span className="h-16">___________________</span>
               <span className="h-16">___________________</span>
            </div>

         </div> */



/*


{(modeArtwork) ? <div className="flex-center h-32 bg-red-300">
                        <div className="h-12 flex-col-center opacity-40" onClick={() => setModeArtwork(!modeArtwork)} >
                           <StickerSkateboard sticker={stickerFound} skateWidth="1.5" />
                        </div>

                        <div className="w-4"></div>

                        <img src={"https://gasparddeshusses.com/images/artworks/" + stickerFound.artworkIdentifier + ".jpg"} alt={stickerFound.artworkIdentifier} className="h-12 " />
                     </div> :
                        <div className="flex-center h-32">
                           <div className="h-12 flex-col-center" >
                              <StickerSkateboard sticker={stickerFound} skateWidth="1.5" />
                           </div>

                           <div className="w-4"></div>

                           <img src={"https://gasparddeshusses.com/images/artworks/" + stickerFound.artworkIdentifier + ".jpg"} alt={stickerFound.artworkIdentifier} className="h-12  opacity-40" onClick={() => setModeArtwork(!modeArtwork)} />
                        </div>}

                     {modeArtwork ?
                        <div id="artworkdiv" className="flex flex-col md:flex-row justify-center items-center bg-red-300 " >
                           <img src={"https://gasparddeshusses.com/images/artworks/" + stickerFound.artworkIdentifier + ".jpg"} alt={stickerFound.artworkIdentifier} className="object-contain h-20" onClick={() => setModeArtwork(!modeArtwork)} />

                           <div className="h-8"></div>

                           <div className="h-full flex-col-center md:pl-16">
                              <StickerTextBlock sticker={stickerFound} width={window.innerWidth - 80} />
                           </div>

                        </div> :
                        <div id="skatediv" className="flex-col-center">

                           <div className="pt-4 h-full flex-col-center" onClick={() => setModeArtwork(!modeArtwork)} >
                              <StickerSkateboard sticker={stickerFound} skateWidth="10" />
                           </div>

                           <div className="h-full hidden md:flex-col-center md:pl-16">
                              <StickerTextBlock sticker={stickerFound} width="400" />
                           </div>

                        </div>}




*/