import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../styles/main.css';

import OriginalDetails from "./OriginalDetails"
import StickerDetails from './StickerDetails';
import PosterDetails from './PosterDetails';
import { SCREEN_WIDTH, SMALL_DEVICE } from '../../helpers/constants';

export default function ComboDetails() {

   const mobileContainer = useRef();

   useEffect(() => {
      if (SMALL_DEVICE && mobileContainer?.current) {
         setTimeout(() => mobileContainer.current.scrollTo({
            left: SCREEN_WIDTH / 2,
            behavior: 'smooth'
         }), 700)
      } else {
         setTimeout(() => window.scrollTo({
            top: 300,
            behavior: 'smooth'
         }), 700)
      }

   }, [SMALL_DEVICE, mobileContainer]);

   if (SMALL_DEVICE) {
      return <section ref={mobileContainer}
         className="flex flex-row-center overflow-y-hidden overflow-x-auto items-center" >

         <OriginalDetails />

         <PosterDetails />

         <StickerDetails />

      </section>;
   }

   return <section className="flex w-full px-40 flex-col-center ">
      <div className="w-full">
         <OriginalDetails />
      </div>

      <div className="w-full">
         <PosterDetails />
      </div>

      <div className="w-full">
         <StickerDetails />
      </div>
   </section>;
}
