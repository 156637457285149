import React, { useState } from 'react';
import '../../../styles/main.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Clothe(prop) {
   const [artworkOnDisplay, setArtworkOnDisplay] = useState(false);
   const [shirtLoaded, setShirtLoaded] = useState(false);


   return (
      <div className={prop.globalClass}>

         <div className="h-2" />

         <Link to={"/clothing/" + prop.clothe.artworkIdentifier}>
            <div className="relative"
               onMouseEnter={() => setArtworkOnDisplay(!artworkOnDisplay)}
               onMouseLeave={() => setArtworkOnDisplay(!artworkOnDisplay)}>

               <div id={"front" + prop.clothe._id}
                  className={artworkOnDisplay
                     ? "flex-col-center"
                     : "opacity-0 flex-col-center"}
               >

                  <LazyLoadImage
                     height={400}
                     src={"https://gasparddeshusses.com/images/products/tshirt/front/" + prop.clothe.artworkIdentifier + ".jpg"}
                     className={prop.imagesClass}
                  />

               </div>

               <div id={"back" + prop.clothe._id}
                  className={artworkOnDisplay
                     ? "opacity-0 parent absolute top-0 flex-col-center w-full"
                     : "parent absolute top-0 flex-col-center w-full"}>

                  <LazyLoadImage
                     height={400}
                     id={"image" + prop.clothe._id}
                     src={"https://gasparddeshusses.com/images/products/tshirt/back/" + prop.clothe.artworkIdentifier + ".jpg"}
                     alt={prop.clothe.artworkIdentifier}
                     className={prop.imagesClass}
                     afterLoad={() => setShirtLoaded(true)}
                     />

               </div>

            </div>

         </Link>

         {shirtLoaded && <div className={prop.infoClass}>
            <h2 className="uppercase font-semibold text-sm">{prop.clothe.type} – {prop.clothe.artworkTitle}</h2>
            <h3 className="text-2xl font-bold">{prop.clothe.price / 100}€</h3>
         </div>}

         <div className="h-6"></div>

      </div>

   );

}


/*
   render() {
    const { title, img, type, price, inCart } = this.props.product;
    return (
      <div className="m-4">
        <div onClick={() => console.log('you clicked on the image container')}>
          <Link to="/details">
            <img src={img} alt="print" className="object-contain h-64" />
          </Link>

          <h1 className="">{type} - {title}</h1>
          <h2 className="">{price} euros</h2>

          <button className="font-bold text-center border-2 border-black px-4 py-1 focus:outline-none focus:bg-black focus:text-white" disabled={inCart ? true : false} onClick={() => console.log("Added to the cart")}>
            {inCart ? (<p disabled> IN CART</p>) : (<p className="hover:text-white">ADD TO CART</p>)}
          </button>
        </div>

      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id:PropTypes.number,
    img:PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    inCart: PropTypes.bool
  }).isRequired
} */