import React from 'react';
import '../../styles/main.css';

export default function Default() {

  return (
    <div className="w-full h-screen flex-col-center">
        <div className="font-black text-9xl">404</div>
        <div className="font-black text-4xl">NOT FOUND</div>
    </div>
  );
}