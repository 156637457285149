import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CartContext from "../../context/CartContext";
import '../../styles/main.css';

export default function CartNotification(props) {

   const [items, setItems] = useState([]);
   const [itemsPrice, setItemsPrice] = useState(0);
   const { setNumberItemsCart } = useContext(CartContext);
   const history = useHistory();

   useEffect(() => {
      const items = JSON.parse(localStorage.getItem('items'));
      if (items) {
         setItems(items);
         setNumberItemsCart(items.reduce((a, c) => a + c.quantity, 0, 0))
         setItemsPrice(items.reduce((a, c) => a + c.price * c.quantity, 0, 0) + 0);
      }
   }, []);

   function decrementQuantity(x) {
      let cartCopy = [...items];
      if (x.quantity !== 1) {
         x.quantity -= 1;
         setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))
         setItems(cartCopy);
         setItemsPrice(cartCopy.reduce((a, c) => a + c.price * c.quantity, 0, 0));
         let stringCart = JSON.stringify(cartCopy);
         localStorage.setItem('items', stringCart);
      } else {
         cartCopy = cartCopy.filter(item => item._id !== x._id);
         setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))
         setItems(cartCopy);
         setItemsPrice(cartCopy.reduce((a, c) => a + c.price * c.quantity, 0, 0));
         let stringCart = JSON.stringify(cartCopy);
         localStorage.setItem('items', stringCart)
      }
   }

   function incrementQuantity(x) {
      let cartCopy = [...items];
      if (x.type !== "original") {
         x.quantity += 1;
      }
      setItems(cartCopy)
      let stringCart = JSON.stringify(cartCopy);
      localStorage.setItem('items', stringCart)
      setItemsPrice(items.reduce((a, c) => a + c.price * c.quantity, 0, 0));
      setNumberItemsCart(items.reduce((a, c) => a + c.quantity, 0, 0))
   }

   return (
      <div className="w-104 absolute px-8 py-4 font-bold z-20 select-none">

         <div className="border-t border-gray-300 w-full py-4 hidden sm:block">
            {(items.length !== 0) ? <div className="w-full ">{items.map((x, index) =>
               <div key={x._id} className="w-full grid grid-cols-12 font-bold">

                  <div className="sm:pl-4 col-span-2 pb-2 flex flex-row justify-end items-center">
                     <img src={"https://gasparddeshusses.com/images/artworks/" + x.artworkIdentifier + ".jpg"} alt={x.artworkIdentifier} className="h-10 object-contain"></img>
                  </div>


                  {x.size && <div className="col-span-5 h-10 flex items-center leading-3 text-xs break-words sm:break-normal font-normal italic pl-3 uppercase hover:font-medium" onClick={() => history.push("/clothes/" + x.artworkIdentifier)}>{x.type}<br />{x.artworkTitle} ({x.size.toString()})</div>}

                  {(x.size === undefined) && <div className="col-span-5 h-10 flex items-center leading-3 text-xs break-words sm:break-normal font-normal italic pl-3 uppercase hover:font-medium" onClick={() => history.push("/artworks/" + x.artworkIdentifier)}>{x.type}<br />{x.artworkTitle}</div>}


                  <div className="bg-white col-span-3 h-10 items-center font-normal leading-tight sm:pl-4 text-center flex justify-center">
                     <div className="flex flex-row h-6 border border-gray-300">
                        <p className="h-6 w-5 text-xl font-bold leading-4 focus:outline-none" onClick={() => decrementQuantity(x)}>-</p>
                        <p className="h-4 w-5 font-bold leading-5 focus:outline-none" onClick={() => decrementQuantity(x)}>{x.quantity}</p>
                        <p className="h-6 w-5 text-xl font-bold leading-4 focus:outline-none" onClick={() => incrementQuantity(x)}>+</p>
                     </div>
                  </div>
                  <div className="col-span-2 h-10 flex items-center justify-end text-right sm:pr-4" onClick={() => console.log(x.size)}>{((x.price) * (x.quantity)) / 100}€</div>
               </div>
            )}</div> : <p className="text-xs font-normal pb-2">EMPTY CART</p>}


            <div className="w-full grid grid-cols-2 font-bold">
               <div className="col-span-2 border-t border-gray-300 my-2"></div>

               <div className="sm:pl-4">Subtotal</div>
               <div className="sm:pr-4 text-right">{itemsPrice / 100}€</div>

               <div className="col-span-2 border-t border-gray-300 my-2"></div>
            </div>


         </div>

         <div className="w-full flex-col-center">
            <button onClick={() => { history.push("/cart") }} className="text-3xl font-bold w-64 py-2 btn-black">CHECKOUT</button>

            <div className="h-3"></div>

            <button onClick={props.clearNotification} className="text-3xl font-bold w-64 py-2 btn-black">STAY HERE</button>
         </div>

      </div >
   );
}
