import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../../styles/main.css';

import Clothe from './Clothe';
import ClotheMobile from './ClotheMobile';


// LAZYLOAD
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export default function Merchandising() {
   const [clothesList, setClothesList] = useState([]);
   const [postersList, setPostersList] = useState([]);
   const [stickersList, setStickersList] = useState([]);

   const [sectionLoaded, setSectionLoaded] = useState(false);


   useEffect(() => {

      let isMounted = true;
      const getItems = async () => {
         const getClothesRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/tshirt");
         const getPostersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/poster");
         const getStickersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/sticker");

         if (isMounted) {
            setClothesList(getClothesRes.data);
            setPostersList(getPostersRes.data);
            setStickersList(getStickersRes.data);
         }
      }

      getItems();
      return () => isMounted = false;
   }, []);

   function horizontalScroll(e) {
      var container = document.getElementById('container');
      var containerScrollPosition = document.getElementById('container').scrollLeft
      container.scrollTo({
         top: 0,
         left: containerScrollPosition + e.deltaY,
         behaviour: 'smooth' //if you want smooth scrolling
      })
   }


   return (
      <div className="m-0 flex-center z-10">


         {/* ----------- BIG SCREENS VERSION ----------- */}
         {(window.innerWidth > 640) && <section id="container"
            className={sectionLoaded
               ? "flex-nowrap overflow-x-auto overflow-y-hidden h-screen flex flex-row items-center"
               : "opacity-0 flex-nowrap overflow-x-auto overflow-y-hidden h-screen flex flex-row items-center"}
            onWheel={horizontalScroll}
            onLoad={() => setSectionLoaded(true)}>

            <div className="flex-grow-0 flex-shrink-0 w-14 h-20" />

            <div className="w-24 flex-grow-0 flex-shrink-0 text-white"></div>


            {clothesList.slice(0, 2).map(item =>
               <Clothe key={item._id} clothe={item} globalClass="w-112 flex-grow-0 flex-shrink-0 mx-12" />
            )}

            <div className="w-112 flex-grow-0 flex-shrink-0 text-white">FIN</div>

         </section>}


         {/* ----------- SMALL SCREENS VERSION ----------- */}
         {(window.innerWidth <= 640) && <div className="w-full">

            <div className="h-32" />

            <section className="flex flex-wrap w-full px-2">
               {clothesList.slice(0, 2).map(item =>
                  <div key={item._id} className="w-full px-10">
                     <div className="h-10" />
                     <ClotheMobile clothe={item} />
                  </div>
               )}
            </section>


            <div className="h-40" />

         </div>}

      </div>
   );
}



/*
<div className="flex justify-between items-start">
                     <div className="w-5/12">
                        <div className="h-10" />
                        {clothesList.slice(0, 1).map((item) =>
                           <Clothe key={item._id} clothe={item} imagesClass="object-contain w-104 mb-4" globalClass="pl-8" />
                        )}
                     </div>
                     <div className="w-5/12">
                        <div className="h-10" />
                        {clothesList.slice(1, 2).map((item) =>
                           <Clothe key={item._id} clothe={item} imagesClass="object-contain w-104 mb-4" globalClass="pl-8" />
                        )}
                     </div>
                     <div className="w-1/12 flex-none"></div>
                  </div>
*/