import React from "react";
import '../../styles/main.css';
import Ig from '../../logos/ig.png';

export default function WitchesAreSaints() {

   return (
      <>

         <div className="w-full md:h-screen flex-col-center">

            <h1 className="fraktur text-7xl pb-12 pt-32 md:pt-0 text-center">Witches Are Saints</h1>

            <p className="chomsky text-2xl text-center pb-10 px-8">‘Witches Are Saints’ is a project combining heavy watercolors and abstract collages, previously posted on Instagram.<br /><br />Motivated by fashion and religious iconography, ‘Witches Are Saints’ aims to <br class="hidden md:block" />question women’s representation in art, from ancient times to our days <br class="hidden md:block" />and explore its inherent ties to eroticism, power and independence.</p>

            <a href="https://instagram.com/ga5parov" target="_blank" rel="noreferrer" className="md:hover:opacity-0 pb-32 md:pb-0">
               <img id="ig" src={Ig}
                  alt="instagram profile" className="h-12" />
            </a>
         </div>

      </>
   );
}



