import React, { useContext, useState } from "react";
import AdminContext from "../../context/AdminContext";
import { Link, useLocation } from 'react-router-dom';
import MENU from './../../logos/admin/MENU.svg'

export default function AuthOptions() {
   const { adminData, setAdminData } = useContext(AdminContext);
   const [menuOpen, setMenuOpen] = useState(false);
   const location = useLocation();
   const parts = location.pathname.split('/');
   const componentName = parts[parts.length - 1];

   const logout = () => {
      setAdminData({
         token: undefined,
         admin: undefined,
      });
      localStorage.setItem("x-auth-token", "");
   };

   return (
      <>
         <nav className="w-full ">
            {adminData.admin ? (
               <div className="flex justify-center items-center font-bold uppercase">
                  {(window.innerWidth > 768) ? <div className="h-20 flex justify-center items-center">
                     <Link to='/admin/dashboard/global' className={`pl-10 pr-3 hover:opacity-100 cursor-default ${componentName === "global" ? "opacity-100" : "opacity-20"}`}>Global</Link>
                     <Link to='/admin/dashboard/artworks' className={`px-3 hover:opacity-100 cursor-default ${componentName === "artworks" ? "opacity-100" : "opacity-20"}`}>Artworks</Link>
                     {/* <Link to='/admin/dashboard/design' className={`px-3 hover:opacity-100 cursor-default ${location === "admin/dashboard/artworks" ? "opacity-100" : "opacity-20"}`}>Design</Link> */}
                     <Link to='/admin/dashboard/products' className={`px-3 hover:opacity-100 cursor-default ${componentName === "products" ? "opacity-100" : "opacity-20"}`}>Products</Link>
                     <Link to='/admin/dashboard/orders' className={`px-3 hover:opacity-100 cursor-default ${componentName === "orders" ? "opacity-100" : "opacity-20"}`}>Orders</Link>
                     {/*<Link to='/admin/dashboard/emails' className="px-3 hover:opacity-100">Emails</Link>*/}

                     <button onClick={logout} className="btn-red z-50 ml-48 text-3xl px-4 py-1 font-bold">LOG OUT</button>
                  </div> :
                     <div className="relative" onClick={() => setMenuOpen(!menuOpen)}>
                        <img className="object-contain h-8 w-8 ml-10" src={MENU} alt="D"></img>
                        {menuOpen && <div className="absolute top-0 w-32 mt-12 flex-col-center bg-white border border-black">
                           <Link to='/admin/dashboard/global' className="pl-10 pr-3 hover:opacity-0 cursor-default">Global</Link>
                           <Link to='/admin/dashboard/artworks' className="py-2">Artworks</Link>
                           <Link to='/admin/dashboard/design' className="py-2">Design</Link>
                           <Link to='/admin/dashboard/products' className="py-2">Products</Link>
                           <Link to='/admin/dashboard/orders' className="py-2">Orders</Link>
                           <button onClick={logout} className="btn-red z-50 absolute top-0 right-0 mt-9 mr-8 text-lg px-2 py-0.5 font-bold">LOG OUT</button>
                        </div>}
                     </div>}


               </div>
            ) : (
               <>
               </>
            )}
         </nav>
      </>

   );
}
