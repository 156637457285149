import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context as UserContext } from '../../context/UserContext';
import { resizeBlock } from '../../helpers/functions';
import '../../styles/main.css';

const ArtworkTextBlock = props => {

   const [prop] = useState(props);
   const { updateLastSeenArtwork } = useContext(UserContext);



   useEffect(() => {
      let inheritedWidth = prop.width - 5;
      let line1 = (document.getElementById("line1" + prop.artwork._id));
      let line2 = (document.getElementById("line2" + prop.artwork._id));
      let line3 = (document.getElementById("line3" + prop.artwork._id));
      let line4 = (document.getElementById("line4" + prop.artwork._id));
      let joker = (document.getElementById("jokerline" + prop.artwork._id));
      let joker2 = (document.getElementById("jokerline2" + prop.artwork._id));

      if (inheritedWidth > 700 && inheritedWidth < 1100) {
         line1.style.fontSize = "190px";
         line3.style.fontSize = "120px";
      }
      else if (inheritedWidth > 1100) {
         line1.style.fontSize = "190px";
         line2.style.fontSize = "90px";
         line3.style.fontSize = "120px";
      }

      let block = [line1, line2, line3];

      if (prop.artwork.title.length < 18) {
         if (inheritedWidth < 800) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }

      } else if (prop.artwork.title.length > 17 && prop.artwork.title.length < 30) {
         let n = line1.innerHTML.split(" ");
         let half1 = n[0] + " " + n[1];
         let half2 = line1.innerHTML.slice(half1.length);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         block.push(joker);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }

      } else {
         let x = line1.innerHTML.split(" ");
         let half1 = x[0] + " " + x[1];
         let half2 = x[2] + " " + x[3] + " " + x[4];
         let half3 = line1.innerHTML.slice(half1.length + half2.length + 1);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         joker2.innerHTML = half3;
         block.push(joker, joker2);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }

      }
   }, [prop]);

   return (
      <>
         <div className="flex-col-center leading-tight font-bold select-none">

            <span id={"line1" + prop.artwork._id} style={{ opacity: "0", fontSize: "155px" }} className="leading-none inline-flex items-end">{prop.artwork.title}</span>
            <span id={"jokerline" + prop.artwork._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-flex items-end leading-none uppercase"></span>
            <span id={"jokerline2" + prop.artwork._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-flex items-end leading-none uppercase"></span>
            <span id={"line2" + prop.artwork._id} style={{ opacity: "0", fontSize: "80px" }} className="inline-flex items-end leading-none uppercase">{(prop.artwork.date).slice(0, 4)}, {prop.artwork.type}, {prop.artwork.location}</span>
            <span id={"line3" + prop.artwork._id} style={{ opacity: "0", fontSize: "80px" }} className="inline-flex items-start leading-none">
               {(prop.artwork.widthCm !== 1) ? <p>{prop.artwork.widthCm} x {prop.artwork.heightCm} cm ({parseInt((prop.artwork.widthCm) / 2.54)} x {parseInt((prop.artwork.heightCm) / 2.54)} in) </p> : <p>UNKNOWN DIMENSIONS</p>}
            </span>
            <span style={{ fontSize: "25px" }} className="mt-6 leading-none inline-flex items-start">
               <Link
                  onClick={() => updateLastSeenArtwork(prop.artwork.identifier)}
                  className="btn-black leading-none px-8 py-3 uppercase" to={"/artworks/" + prop.artwork.identifier}>Explore</Link>
            </span>

         </div>
      </>
   )
};

export default ArtworkTextBlock;