import React, { useEffect, useState } from 'react';
import { resizeBlock } from '../../../helpers/functions';
import '../../../styles/main.css';

const ClotheTextBlock = (prop) => {

   useEffect(() => {
      let inheritedWidth = prop.width;
      let line1 = (document.getElementById("line1" + prop.clothe._id));
      let line2 = (document.getElementById("line2" + prop.clothe._id));
      let line3 = (document.getElementById("line3" + prop.clothe._id));
      let line4 = (document.getElementById("line4" + prop.clothe._id));
      let line5 = (document.getElementById("line5" + prop.clothe._id));

      let joker = (document.getElementById("jokerline" + prop.clothe._id));
      let joker2 = (document.getElementById("jokerline2" + prop.clothe._id));

      if (inheritedWidth > 700 && inheritedWidth < 1100) {
         line1.style.fontSize = "190px";
         line3.style.fontSize = "120px";
      }
      else if (inheritedWidth > 1100) {
         line1.style.fontSize = "190px";
         line2.style.fontSize = "90px";
         line3.style.fontSize = "120px";
         line4.style.fontSize = "160px";
      }

      let block = [line1, line2, line3, line4, line5];

      if (prop.clothe.artworkTitle.length < 18) {
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }
      } else if (prop.clothe.artworkTitle.length > 17 && prop.clothe.artworkTitle.length < 30) {
         let x = line1.innerHTML.split(" ");
         let half1 = x[0] + " " + x[1];
         let half2 = line1.innerHTML.slice(half1.length);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         block.push(joker);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }

      } else if (prop.clothe.artworkTitle.length > 29) {
         let x = line1.innerHTML.split(" ");
         let half1 = x[0] + " " + x[1];
         let half2 = x[2] + " " + x[3] + " " + x[4];
         let half3 = line1.innerHTML.slice(half1.length + half2.length + 1);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         joker2.innerHTML = half3;
         block.push(joker, joker2);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }
      }

   }, []);






   return (
      <>
         {prop.clothe && <div className="flex-col-center leading-tight font-bold select-none">

            <span id={"line1" + prop.clothe._id} style={{ opacity: "0", fontSize: "140px" }} className="leading-none">{prop.clothe.artworkTitle}</span>
            <span id={"jokerline" + prop.clothe._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-flex items-end leading-none uppercase"></span>
            <span id={"jokerline2" + prop.clothe._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-flex items-end leading-none uppercase"></span>
            <span id={"line2" + prop.clothe._id} style={{ opacity: "0", fontSize: "100px" }} className="uppercase">ORGANIC COTTON</span>
            <span id={"line3" + prop.clothe._id} style={{ opacity: "0", fontSize: "100px" }} className="">220 grams /m2</span>
            <span id={"line4" + prop.clothe._id} style={{ opacity: "0", fontSize: "100px" }} className="leading-none">{(prop.clothe.price) / 100} EUROS</span>


            <select
               defaultValue=""
               id={"line5" + prop.clothe._id}
               style={{ opacity: "0", fontSize: "140px" }}
               className="leading-none uppercase outline-none border-transparent font-bold bg-transparent"
               onChange={prop.onChangeSize}>
               <option key={546} className="font-bold leading-tight select-none" value="" disabled hidden>PICK A SIZE</option>
               {prop.clothe.sizes.map((size, index) => <option key={index} className="font-bold leading-tight select-none" value={size}>{`SIZE ${size}`}</option>)}
            </select>

            <span style={{ fontSize: "25px" }} className={`bg-black text-white leading-none px-8 py-3 mt-6 ${prop.size ? "btn-black" : "opacity-20"}`} onClick={prop.size ? prop.onOrder : null}>ORDER</span>


         </div>}
      </>
   )
};

export default ClotheTextBlock;

   // POELE, SKATE, RADIATEUR, BAIGNOIRE, SELLE DE VELO, TONDEUSE, TRONCONNEUSE
   // CAMION, VOITURE

