import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../../styles/main.css';
import ClotheTextBlock from './ClotheTextBlock';
import CartNotification from '../../cart/CartNotification';
import CartContext from "../../../context/CartContext";
import { SCREEN_HEIGHT, SMALL_DEVICE } from '../../../helpers/constants';


export default function ClotheDetails() {
   const [clotheFound, setClotheFound] = useState();
   const [sasOpened, setSasOpened] = useState(false);

   let { identifier } = useParams();

   const [modeFront, setModeFront] = useState(true);

   useEffect(() => {
      let isSubscribed = true;
      const getTshirt = async () => {
         const getTshirtsRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/tshirt/" + identifier);
         console.log(getTshirtsRes)
         if (isSubscribed) {
            setClotheFound(getTshirtsRes.data);
         }
      };
      getTshirt();
      return () => isSubscribed = false
   }, []);

   // Get div center on the actual mobile browser viewport height
   const [mobileScreenHeight, setMobileScreenHeight] = useState(700)

   useEffect(() => {
      setMobileScreenHeight(window.innerHeight)
   }, []);

   // ----------- ITEMS IN CART ----------- //
   const [items, setItems] = useState([]);
   const { setNumberItemsCart } = useContext(CartContext);
   const [sizeChosen, setSizeChosen] = useState();

   useEffect(() => {
      const items = JSON.parse(localStorage.getItem('items'));
      if (items) {
         setItems(items);
         setNumberItemsCart(items.reduce((a, c) => a + c.quantity, 0, 0))
      }
   }, [setNumberItemsCart]);


   const addToCart = () => {
      let cartCopy = [...items];
      let existingItem = cartCopy.find(item => item._id === (clotheFound._id));

      if (existingItem) {
         existingItem.quantity += 1; //update item
         let sizeCopy = [existingItem.size];
         sizeCopy.push(sizeChosen);
         existingItem.size = sizeCopy;
         setItems(cartCopy);
         setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))
         console.log(cartCopy);

      } else { //if item doesn't exist, simply add it
         clotheFound.quantity = 1;
         clotheFound.size = sizeChosen;
         cartCopy.push(clotheFound)
         setItems(cartCopy);
         setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))
         console.log(cartCopy);
      }

      let stringCart = JSON.stringify(cartCopy);
      localStorage.setItem('items', stringCart);
      setSasOpened(true);
   }

   if (!clotheFound) {
      return <div className="w-full h-screen "></div>;
   }

   if (SMALL_DEVICE && !sasOpened) {
      return <div className="flex flex-col-center w-full " >

         <div className="w-full flex flex-col justify-start items-center">

            <div className="sticky top-0 flex-center w-40 z-50 bg-white" style={{ height: 0.12 * SCREEN_HEIGHT }}>
               <img src={"https://gasparddeshusses.com/images/products/tshirt/front/" + clotheFound.artworkIdentifier + ".jpg"} alt={clotheFound.artworkIdentifier} className={modeFront ? "h-4/5 opacity-40 z-50" : "h-4/5 opacity-100 z-50"} onClick={() => setModeFront(false)} />

               <div className="w-4 flex-none" />

               <img src={"https://gasparddeshusses.com/images/products/tshirt/back/" + clotheFound.artworkIdentifier + ".jpg"} alt={clotheFound.artworkIdentifier} className={modeFront ? "h-4/5 opacity-100 z-50" : "h-4/5 opacity-40 z-50"} onClick={() => setModeFront(true)} />
            </div>


            <div className="flex-col-center w-full">
               <img src={modeFront ? ("https://gasparddeshusses.com/images/products/tshirt/back/" + clotheFound.artworkIdentifier + ".jpg") : ("https://gasparddeshusses.com/images/products/tshirt/front/" + clotheFound.artworkIdentifier + ".jpg")} alt={clotheFound.artworkIdentifier} className="object-contain w-96" onClick={() => setModeFront(!modeFront)} />

               <div className="h-12" />

               <div className="w-full">
                  <ClotheTextBlock
                     clothe={clotheFound}
                     width={300}
                     onOrder={addToCart}
                     onChangeSize={(e) => setSizeChosen(e.target.value)}
                     size={sizeChosen}
                  />
               </div>

               <div className="h-40" />
            </div>

         </div>
      </div>
   }


   return <div className="flex-col-center">

      {(sasOpened) && <div className="absolute top-0 w-full h-screen bg-white flex-center z-">
         <CartNotification clearNotification={() => setSasOpened(false)} />
      </div>}


      {!sasOpened
         ? <div className="flex flex-col w-full h-screen justify-start items-center">

            <div className="flex-center h-32">
               <img src={"https://gasparddeshusses.com/images/products/tshirt/front/" + clotheFound.artworkIdentifier + ".jpg"} alt={clotheFound.artworkIdentifier} className={modeFront ? "h-12 opacity-40 z-50" : "h-12 opacity-100 z-50"} onClick={() => setModeFront(false)} />

               <div className="w-2" />

               <img src={"https://gasparddeshusses.com/images/products/tshirt/back/" + clotheFound.artworkIdentifier + ".jpg"} alt={clotheFound.artworkIdentifier} className={modeFront ? "h-12 opacity-100 z-50" : "h-12 opacity-40 z-50"} onClick={() => setModeFront(true)} />
            </div>

            <div className="flex-center w-full h-full pb-12" >

               <img src={modeFront ? ("https://gasparddeshusses.com/images/products/tshirt/back/" + clotheFound.artworkIdentifier + ".jpg") : ("https://gasparddeshusses.com/images/products/tshirt/front/" + clotheFound.artworkIdentifier + ".jpg")} alt={clotheFound.artworkIdentifier} className="object-contain h-136 max-w-3xl" onClick={() => setModeFront(!modeFront)} />

               <div className="h-full flex-col-center pl-16">
                  <ClotheTextBlock
                     clothe={clotheFound}
                     width="300"
                     onOrder={() => addToCart()}
                     onChangeSize={(e) => setSizeChosen(e.target.value)}
                     size={sizeChosen}
                  />
               </div>

            </div>

         </div>
         : null}

   </div>;
}

