import React, { Component } from 'react';
import '../../styles/main.css';

export default class DashboardEmails extends Component {
   render() {
      return (
         <div className="pt-20">
         Emails Dashboard</div>
      );
    }
}