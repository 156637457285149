import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../../styles/main.css';
import ErrorNotice from '../utils/ErrorNotice';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TRASH from './../../logos/admin/TRASH.svg'
import DeleteConfirmation from "../utils/DeleteConfirmation";
import { SCREEN_HEIGHT } from "../../helpers/constants";

export default function DashboardGlobal() {

   const [adminData, setAdminData] = useState();
   const [error, setError] = useState();
   const [identifier, setIdentifier] = useState();
   const [image, setImage] = useState();
   const [preview, setPreview] = useState();
   const [selectedFile, setSelectedFile] = useState();
   const [loading, setLoading] = useState(false);
   let fileInputElement = document.querySelector("#fileInputElement")

   useEffect(() => {
      if (!selectedFile) {
         setPreview(undefined);
         return
      }

      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
   }, [selectedFile])


   useEffect(() => {
      const checkLoggedIn = async () => {
         let token = localStorage.getItem("x-auth-token");
         if (token === null) {
            localStorage.setItem("x-auth-token", "");
            token = "";
         }
         const tokenValidRes = await axios.post(
            "https://gasparddeshusses.herokuapp.com/api/admin/tokenIsValid", null, { headers: { "x-auth-token": token } }
         );
         if (tokenValidRes.data) {
            const adminRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/", {
               headers: { "x-auth-token": token },
            });
            setAdminData({
               token,
               admin: adminRes.data,
            });

            const getBackgroundRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/background/", {
               headers: { "x-auth-token": token },
            });

            setImage(getBackgroundRes.data[0]);
            setLoading(false);
         }
      };


      checkLoggedIn();
   }, []);

   const addBackground = async (e) => {
      e.preventDefault();
      try {
         const formData = new FormData();
         formData.append('identifier', identifier)
         formData.append('image', fileInputElement.files[0])
         await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/add-background", formData, {
            headers: { "x-auth-token": adminData.token },
         });
         document.querySelector("#submitButton").blur();
         setSelectedFile(fileInputElement.files[0])

      } catch (error) {
         setError(error.response.data.error);
      }
   };

   if (loading) {
      return <div className="w-screen h-screen flex justify-center items-center" >
         <p>Chargement...</p>
      </div >
   }

   return (
      <>
         <div className="w-full flex-col-center">
            <div className="hidden md:block mb-12" style={{ height: 0.12 * SCREEN_HEIGHT }}></div>

            <div className="flex flex-col-reverse md:flex-row justify-between items-start pt-24 md:pt-0 md:px-24 ml-48">

               <form id="myForm" onSubmit={addBackground} className="w-full md:w-96 flex flex-col justify-center items-center md:items-start text-xl text-center">

                  <div className="h-2"></div>

                  <input required onChange={(e) => setIdentifier(e.target.value)} className="focus w-80 pl-2" placeholder="Identifiant" value={identifier}></input>

                  <div className="h-6"></div>

                  <div className="relative">
                     <input id="fileInputElement"
                        type="file" className="w-80 flex justify-between bg-white focus:outline-none text-lg bg-gradient-to-r from-white z-20 border-transparent" required />

                     <div className="absolute top-0 w-36 ring-8 ring-white h-8 flex-center text-sm font-medium btn-black"
                        onClick={() => fileInputElement.click()}>CHOISIR FICHIER</div>
                  </div>

                  <div className="flex-col-center w-80 mt-8">
                     <input id="submitButton" type="submit" value="MODIFIER LE FOND D'ÉCRAN" className="text-xl font-bold w-88 py-2 btn-black text-center px-6" style={{ maxWidth: "28rem" }}></input>

                  </div>

               </form>


               <div className="w-full md:w-160 flex flex-col h-screen">
                  <img src={preview ? preview : image?.src}
                     alt={image?.identifier}
                     className="w-full h-112 object-cover" />
               </div>

            </div>
         </div>
      </>

   );
}
