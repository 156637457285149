import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import '../../styles/main.css';
import axios from 'axios';

export default function OrderRedirect() {
   const [orderFound, setOrderFound] = useState("");

   let { id } = useParams();

   useEffect(() => {
      let isSubscribed = true;
      const getOrder = async () => {
         const getOrdersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/orders/get/" + id);

         if (isSubscribed) {
            setOrderFound(getOrdersRes.data);
         }
      };

      getOrder();
      window.top.postMessage("3DS-authentication-complete");

      return () => isSubscribed = false
   }, []);


   

  return (
    <>
    </>
  );
}
