import '../../styles/main.css';
import { Link } from 'react-router-dom';



export default function Legal() {
   return (
      <div className="w-full flex-col-center leading-6">
         <div className="h-20"></div>

         <div className="sticky top-0 bg-white z-40 h-20 w-full md:hidden"></div>

         <div className="w-80 md:w-220 text-center md:text-lg z-50">


            <div className="w-full text-center text-4xl md:text-6xl font-black">LEGAL INFORMATION</div>
            <div className="w-full text-center md:text-base italic pt-2">Last updated on <br className="md:hidden" />Tuesday, January 19th, 2021</div>

            <div className="h-8"></div>

            <h2 className="text-xl md:text-2xl pt-4 font-bold">WEBSITE EDITOR</h2>
            <p>Grégoire Deshusses (19 Rue Oberkampf, 75011 Paris).</p>

            <h2 className="text-xl md:text-2xl pt-4 font-bold">DIRECTOR OF PUBLICATION</h2>
            <p>Grégoire Deshusses (contact@gasparddeshusses.com).</p>

            <h2 className="text-xl md:text-2xl pt-4 font-bold">HOSTING</h2>
            <p>Netlify (Netlify 2325 3rd St #215 San Francisco CA 94107 USA).</p>

            <div className="h-8" />

            <p>For more legal information, please refer to our <Link to="/terms-and-conditions" className="underline font-bold hover:opacity-0">Terms and conditions</Link></p>

            <div className="h-64" />



         </div>
      </div>
   );
}



