import React, { useEffect, useState, useContext } from 'react';
import '../../../styles/main.css';
import CartContext from "../../../context/CartContext";
import CartNotification from '../../cart/CartNotification';
import { resizeBlock } from '../../../helpers/functions';

const PosterTextBlock = props => {

   const [prop] = useState(props);
   const [notification, setNotification] = useState(false);

   useEffect(() => {
      let inheritedWidth = prop.width;
      let line1 = (document.getElementById("line1" + prop.poster._id));
      let line2 = (document.getElementById("line2" + prop.poster._id));
      let line3 = (document.getElementById("line3" + prop.poster._id));
      let line4 = (document.getElementById("line4" + prop.poster._id));
      let line5 = (document.getElementById("line5" + prop.poster._id));

      let joker = (document.getElementById("jokerline" + prop.poster._id));
      let joker2 = (document.getElementById("jokerline2" + prop.poster._id));

      if (inheritedWidth > 700 && inheritedWidth < 1100) {
         line1.style.fontSize = "190px";
         line3.style.fontSize = "120px";
      }
      else if (inheritedWidth > 1100) {
         line1.style.fontSize = "190px";
         line2.style.fontSize = "90px";
         line3.style.fontSize = "120px";
         line4.style.fontSize = "160px";
      }

      let block = [line1, line2, line3, line4, line5];

      if (prop.poster.artworkTitle.length < 18) {
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }
      } else if (prop.poster.artworkTitle.length > 17 && prop.poster.artworkTitle.length < 30) {
         let x = line1.innerHTML.split(" ");
         let half1 = x[0] + " " + x[1];
         let half2 = line1.innerHTML.slice(half1.length);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         block.push(joker);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }

      } else if (prop.poster.artworkTitle.length > 29) {
         let x = line1.innerHTML.split(" ");
         let half1 = x[0] + " " + x[1];
         let half2 = x[2] + " " + x[3] + " " + x[4];
         let half3 = line1.innerHTML.slice(half1.length + half2.length + 1);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         joker2.innerHTML = half3;
         block.push(joker, joker2);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }
      }

   }, [prop]);


   // ----------- ITEMS IN CART ----------- //
   const [items, setItems] = useState([]);
   const { setNumberItemsCart } = useContext(CartContext);

   useEffect(() => {
      const items = JSON.parse(localStorage.getItem('items'));
      if (items) {
         setItems(items);
         setNumberItemsCart(items.reduce((a, c) => a + c.quantity, 0, 0))
      }
   }, [setNumberItemsCart]);


   const addToCart = () => {
      if (!notification) {
         let cartCopy = [...items];
         let existingItem = cartCopy.find(item => item._id === (prop.poster._id));

         if (existingItem) {
            existingItem.quantity += 1; //update item
            setItems(cartCopy);
            setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))

         } else { //if item doesn't exist, simply add it
            prop.poster.quantity = 1;
            cartCopy.push(prop.poster)
            setItems(cartCopy);
            setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))
         }

         let stringCart = JSON.stringify(cartCopy);
         localStorage.setItem('items', stringCart)
         setNotification(true);
      }
   }



   return (
      <>
         {notification ? <CartNotification clearNotification={() => setNotification(false)} /> : <p></p>}


         <div className={notification ? "flex-col-center leading-tight font-bold select-none opacity-0" : "flex-col-center leading-tight font-bold select-none"}>

            <span id={"line1" + prop.poster._id} style={{ opacity: "0", fontSize: "140px" }} className="leading-none">{prop.poster.artworkTitle}</span>
            <span id={"jokerline" + prop.poster._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-flex items-end leading-none uppercase"></span>
            <span id={"jokerline2" + prop.poster._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-flex items-end leading-none uppercase"></span>
            <span id={"line2" + prop.poster._id} style={{ opacity: "0", fontSize: "100px" }} className="leading-none uppercase">{prop.poster.type}</span>
            <span id={"line3" + prop.poster._id} style={{ opacity: "0", fontSize: "100px" }}>
               {(prop.poster.artworkWidthCm !== 1) ? <p>{parseInt(prop.poster.artworkWidthCm)} x {parseInt(prop.poster.artworkHeightCm)} cm ({parseInt((prop.poster.artworkWidthCm) / 2.54)} x {parseInt((prop.poster.artworkHeightCm) / 2.54)} in)</p> : <p>{parseInt(prop.poster.imageWidth * 3 / 100)} x {parseInt(prop.poster.imageHeight * 3 / 100)} cm ({parseInt((prop.poster.imageWidth * 3 / 100) / 2.54)} x {parseInt((prop.poster.imageHeight * 3 / 100) / 2.54)} in)</p>}
            </span>
            <span id={"line4" + prop.poster._id} style={{ opacity: "0", fontSize: "100px" }} className="leading-none">{(prop.poster.price) / 100} EUROS</span>

            <span className="h-3"></span>
            <span id={"line5" + prop.poster._id} style={{ opacity: "0", fontSize: "100px" }} className="btn-black leading-none px-8 py-3 active:text-black" onClick={addToCart}>ORDER</span>


         </div>
      </>
   )
};

export default PosterTextBlock;

   // POELE, SKATE, RADIATEUR, BAIGNOIRE, SELLE DE VELO, TONDEUSE, TRONCONNEUSE
   // CAMION, VOITURE
