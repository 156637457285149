import React, { useState } from 'react';
import '../../../styles/main.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function PosterMobile(prop) {
   const [imageLoaded, setImageLoaded] = useState(false);

   return (
      <div className="w-1/2 px-4 flex flex-col justify-start items-start">
         <Link to={"/posters/" + prop.poster.artworkIdentifier} >

            <div className="w-full h-44 flex-center bg-gray-50">

               <LazyLoadImage
                  id={"image" + prop.poster._id}
                  src={"https://gasparddeshusses.com/images/artworks/" + prop.poster.artworkIdentifier + ".jpg"}
                  alt={prop.poster.artworkIdentifier}
                  className="object-contain w-full"
                  style={{ maxWidth: "80%", maxHeight: "80%" }}
                  afterLoad={() => setImageLoaded(true)}
               />

            </div>
         </Link>

         <div className="h-1" />

         <div className={imageLoaded ? "block" : "opacity-0"}>
            <h2 className="uppercase font-semibold text-xs pr-2">{prop.poster.type} – {prop.poster.artworkTitle}</h2>
            <h3 className="text-xl font-bold">{prop.poster.price / 100}€</h3>
         </div>

         <div className="h-4" />

      </div>

   );

}


/*
   render() {
    const { title, img, type, price, inCart } = this.props.product;
    return (
      <div className="m-4">
        <div onClick={() => console.log('you clicked on the image container')}>
          <Link to="/details">
            <img src={img} alt="print" className="object-contain h-64" />
          </Link>

          <h1 className="">{type} - {title}</h1>
          <h2 className="">{price} euros</h2>

          <button className="font-bold text-center border-2 border-black px-4 py-1 focus:outline-none focus:bg-black focus:text-white" disabled={inCart ? true : false} onClick={() => console.log("Added to the cart")}>
            {inCart ? (<p disabled> IN CART</p>) : (<p className="hover:text-white">ADD TO CART</p>)}
          </button>
        </div>

      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id:PropTypes.number,
    img:PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    inCart: PropTypes.bool
  }).isRequired
} */