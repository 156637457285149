import React, { useEffect, useState, useContext } from 'react';
import '../../styles/main.css';
import CartContext from "../../context/CartContext";
import CartNotification from '../cart/CartNotification';
import { Link } from 'react-router-dom';
import { resizeBlock } from '../../helpers/functions';

const OriginalTextBlock = props => {

   const [prop] = useState(props);
   const [notification, setNotification] = useState(false);

   useEffect(() => {
      let inheritedWidth = prop.width - 5;

      let line1 = (document.getElementById("line1" + prop.original._id));
      let line2 = (document.getElementById("line2" + prop.original._id));
      let line3 = (document.getElementById("line3" + prop.original._id));
      let line4 = (document.getElementById("line4" + prop.original._id));
      let line5 = (document.getElementById("line5" + prop.original._id));
      let line6 = (document.getElementById("line6" + prop.original._id));



      let joker = (document.getElementById("jokerline" + prop.original._id));
      let joker2 = (document.getElementById("jokerline2" + prop.original._id));

      {/**
      if (inheritedWidth <= 1100) {
         line1.style.fontSize = "190px";
         joker.style.fontSize = "100px";
         joker2.style.fontSize = "100px";

         line2.style.fontSize = "100px";
         line3.style.fontSize = "100px";
         line4.style.fontSize = "100px";
         line5.style.fontSize = "100px";
         line6.style.fontSize = "100px";
      }
      else if (inheritedWidth > 1100) {
         line1.style.fontSize = "190px";
         joker.style.fontSize = "100px";
         joker2.style.fontSize = "100px";

         line2.style.fontSize = "100px";
         line3.style.fontSize = "100px";
         line4.style.fontSize = "100px";
         line5.style.fontSize = "100px";
         line6.style.fontSize = "100px";
      } */}

      let block = [line1, line2, line3, line4, line5];

      if (prop.original.artworkTitle.length < 18) {
         if (inheritedWidth < 800) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }

      } else if (prop.original.artworkTitle.length > 17 && prop.original.artworkTitle.length < 30) {
         let n = line1.innerHTML.split(" ");
         let half1 = n[0] + " " + n[1];
         let half2 = line1.innerHTML.slice(half1.length);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         block.push(joker);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }
      } else {
         let x = line1.innerHTML.split(" ");
         let half1 = x[0] + " " + x[1];
         let half2 = x[2] + " " + x[3] + " " + x[4];
         let half3 = line1.innerHTML.slice(half1.length + half2.length + 1);
         line1.innerHTML = half1;
         joker.innerHTML = half2;
         joker2.innerHTML = half3;
         block.push(joker, joker2);
         if (inheritedWidth < 300) { // for very short blocks, narrow the step
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.25))
         } else {
            block.forEach(line => resizeBlock(line, inheritedWidth, 0.5))
         }
      }
   }, [prop.original._id, prop.original.artworkTitle.length, prop.width]);



   // ----------- ITEMS IN CART ----------- //
   const [items, setItems] = useState([]);
   const { setNumberItemsCart } = useContext(CartContext);

   useEffect(() => {
      const items = JSON.parse(localStorage.getItem('items'));
      if (items) {
         setItems(items);
         setNumberItemsCart(items.reduce((a, c) => a + c.quantity, 0, 0))
      }
   }, [setNumberItemsCart]);


   const addToCart = () => {
      if (!notification) {
         let cartCopy = [...items];
         let existingItem = cartCopy.find(item => item._id === (prop.original._id));

         if (existingItem) {
            // do nothing as there is only one original
            setItems(cartCopy);
            setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))
         } else { //if item doesn't exist, simply add it
            prop.original.quantity = 1;
            cartCopy.push(prop.original)
            setItems(cartCopy);
            setNumberItemsCart(cartCopy.reduce((a, c) => a + c.quantity, 0, 0))
            console.log(cartCopy)

         }

         let stringCart = JSON.stringify(cartCopy);
         localStorage.setItem('items', stringCart);
         setNotification(true);
      }
   }


   return (
      <>

         {notification ? <CartNotification clearNotification={() => setNotification(false)} /> : <p></p>}

         <div className={notification ? "w-full flex-grow-0 flex-shrink-0 flex-col-center leading-tight font-bold select-none opacity-0" : "w-full flex-grow-0 flex-shrink-0 flex-col-center leading-tight font-bold select-none"}>

            <div id={"line1" + prop.original._id} style={{ opacity: "0", fontSize: "155px" }} className="leading-none inline-block items-end">{prop.original.artworkTitle}</div>
            <div id={"jokerline" + prop.original._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-block items-end leading-none uppercase"></div>
            <div id={"jokerline2" + prop.original._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-block items-end leading-none uppercase"></div>

            <div id={"line2" + prop.original._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-block items-start leading-none uppercase">{(prop.original.artworkDate).slice(0, 4)}, {prop.original.artworkType}, {prop.original.artworkLocation}</div>

            {prop.original.forSale
               ? <div id={"line3" + prop.original._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-block items-end leading-none">{["collage", "assemblage"].includes(prop.original.artworkType.toLowerCase()) ? "ORIGINAL ARTWORK" : "ORIGINAL PAINTING"}</div>
               : null}

            <div id={"line4" + prop.original._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-block items-start leading-none">{(prop.original.artworkWidthCm !== 1) ? <p>{prop.original.artworkWidthCm} x {prop.original.artworkHeightCm} cm ({parseInt((prop.original.artworkWidthCm) / 2.54)} x {parseInt((prop.original.artworkHeightCm) / 2.54)} in) </p> : <p>UNKNOWN DIMENSIONS</p>}</div>

            {prop.original.forSale
               ? prop.original.price
                  ? <div id={"line5" + prop.original._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-block items-start leading-none"><p>{prop.original.price / 100} EUROS</p></div>
                  : null
               : <div id={"line5" + prop.original._id} style={{ opacity: "0", fontSize: "120px" }} className="inline-block items-start leading-none"><p>ORIGINAL SOLD</p></div>}


            {prop.original.price || prop.original.forSale === false
               ? <span style={{ fontSize: "25px", pointerEvents: prop.original.forSale ? "auto" : "none" }} className={`btn-black leading-none px-8 py-3 mt-6 ${prop.original.forSale === false && "opacity-20"}`} onClick={addToCart}>ORDER</span>
               : <Link style={{ fontSize: "25px", pointerEvents: prop.original.forSale ? "auto" : "none" }} className={`btn-black leading-none px-8 py-3 mt-6 ${prop.original.forSale === false && "opacity-20"}`} to={{ pathname: '/contact', state: { subject: prop.original.artworkTitle + " - " + prop.original.artworkType, origin: "price on demand" } }}>PRICE ON DEMAND</Link>}


         </div>
      </>
   )
};

export default OriginalTextBlock;