import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/main.css';
import Social from "../views/Social";

export default function OrderConfirmation() {
   const [orderFound, setOrderFound] = useState("");
   const [loaded, setLoaded] = useState(false);

   let { id } = useParams();

   useEffect(() => {
      let isSubscribed = true;
      const getOrder = async () => {
         const getOrdersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/orders/get/" + id);

         if (isSubscribed) {
            setOrderFound(getOrdersRes.data);
         }
      };

      getOrder();
      return () => isSubscribed = false

   }, []);




   return (
      <div className={loaded
         ? "flex flex-col w-full md:h-screen justify-start items-center"
         : "flex flex-col w-full md:h-screen justify-start items-center opacity-0"} onLoad={() => setLoaded(true)}>

         {orderFound && <div className="md:h-screen flex flex-col md:flex-row justify-center items-center w-full">

            <div className="h-20 md:hidden"></div>

            <div className="sticky top-0 bg-white z-40 h-20 w-full md:hidden"></div>

            <div className="px-8 flex-none w-full md:px-12 sm:w-104 md:w-160 z-50">
               <p className="text-3xl md:text-4xl font-black">🎨 Thanks for your order!</p>

               <div className="h-4"></div>

               <p className="md:text-xl font-black md:pl-2">You will receive a confirmation email within a day at {orderFound.emailSecured}.</p>

               <div className="h-3"></div>

               <p className="md:pl-2">Delivery may take up to 3 weeks as we are dependent on our furnishers. We’ll do our very best, thanks for your understanding! If you have any questions, feel free to contact us!</p>

               <div className="h-8"></div>

               <Social />
            </div>

            <div className="w-full h-12 md:h-12 bg-white md:w-0 z-50"></div>

            <div className="flex-none w-80 sm:w-104 md:w-112 border border-gray-300 z-50">
               <div className="h-4 w-full "></div>
               {orderFound.orderItems.map((x) =>
                  <div key={x._id} className="grid grid-cols-12 font-bold">

                     <div className="sm:pl-4 col-span-3 pb-2 flex-center">
                        <img src={"https://gasparddeshusses.com/images/artworks/" + x.artworkIdentifier + ".jpg"} alt={x.artworkIdentifier} className="h-10 md:h-16 object-contain"></img>
                     </div>

                     <div className="col-span-5 h-16 flex items-center leading-none md:text-xl break-words sm:break-normal font-normal italic pl-3 uppercase select-none" >

                        <Link target="_blank" className="font-bold hover:opacity-0" to={"/" + x.type + "s/" + x.artworkIdentifier}>
                           {x.type}<br />{x.artworkTitle}
                        </Link>

                     </div>

                     <div className="h-16 col-span-2 flex items-center justify-start text-xl md:text-2xl">x{x.quantity}</div>

                     <div className="pr-6 h-16 col-span-2 text-2xl md:text-3xl flex items-center justify-end text-right">{((x.price) * (x.quantity)) / 100}€</div>

                     <div className="col-span-12 h-2"></div>
                  </div>)}

               <div className="grid grid-cols-12 font-bold px-6">
                  <div className="col-span-12 border-t border-gray-300 my-2"></div>
                  <div className="h-10 col-span-10 text-xl flex items-center justify-start text-left">Shipping fees</div>
                  <div className="h-10 col-span-2 text-2xl md:text-3xl flex items-center justify-end text-right">{(orderFound.shippingPaid) / 100}€</div>
                  <div className="col-span-12 border-t border-gray-300 my-2"></div>
               </div>

               <div className="grid grid-cols-12 font-bold">
                  <div className="pl-6 h-10 col-span-10 text-xl flex items-center justify-start text-left">Final price</div>
                  <div className="pr-6 h-10 col-span-2 text-2xl md:text-3xl flex items-center justify-end text-right">{(orderFound.pricePaid) / 100}€</div>
                  <div className="col-span-12 h-4"></div>
               </div>
            </div>

            <div className="w-28 h-28"></div>

         </div>}

      </div>
   );
}

/*
 <div className="w-112 flex flex-col justify-start items-start h-1/3 pb-12">
               <a href="https://instagram.com/gasparddeshusses" target="_blank" rel="noreferrer" className="flex flex-row">
                  <img src={INSTAGRAM} alt="Social Media" className="h-10 lg:h-14 object-fill w-full hover:opacity-0" />
               </a>
               <Link to="/information" target="_blank">
                  <img src={INFORMATION} alt="Information" className="h-10 lg:h-14 object-fill w-full hover:opacity-0" />
               </Link>
            </div> */