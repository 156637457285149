import React, { useState, useEffect } from 'react';
import '../../styles/main.css';
import SKATEBOARD from '../../logos/merch/SKATEBOARD.png'
import SKATEBOARD_VERTICAL from '../../logos/merch/SKATEBOARD_VERTICAL.png'

import { SMALL_DEVICE } from '../../helpers/constants';

export default function StickerSkateboard(prop) {

   const [skateLoaded, setSkateLoaded] = useState(false);
   const [stickerLoaded, setStickerLoaded] = useState(false);

   if (SMALL_DEVICE) {
      return (
         <div className="flex-grow-0 flex-shrink-0" >
            <div id="test" className={(skateLoaded && stickerLoaded)
               ? "relative flex flex-col items-center"
               : "bg-white relative flex flex-col items-center"}>

               <img
                  height={100}
                  src={SKATEBOARD} alt="Skateboard"
                  style={{ width: prop.skateWidth + "rem" }}
                  className={(skateLoaded && stickerLoaded)
                     ? "object-contain"
                     : "opacity-0 object-contain"}
                  onLoad={() => setSkateLoaded(true)}
               />

               {(prop.sticker.orientation === "vertical") &&
                  <img id={"image" + prop.sticker._id}
                     src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                     alt={prop.sticker.artworkIdentifier}
                     className="absolute inset-0 m-auto"
                     style={{ width: (prop.skateWidth) / 9 + "rem" }}
                     onLoad={() => setStickerLoaded(true)}
                  />}

               {(prop.sticker.orientation === "square_vertical" || prop.sticker.orientation === "square_horizontal") &&
                  <img id={"image" + prop.sticker._id}
                     src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                     alt={prop.sticker.artworkIdentifier}
                     className="absolute inset-0 m-auto"
                     style={{ width: (prop.skateWidth) / 7.5 + "rem" }}
                     onLoad={() => setStickerLoaded(true)}
                  />}

               {(prop.sticker.orientation === "horizontal") &&
                  <img id={"image" + prop.sticker._id}
                     src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                     alt={prop.sticker.artworkIdentifier}
                     className={(skateLoaded && stickerLoaded)
                        ? "absolute inset-0 transform -rotate-90 m-auto"
                        : "opacity-0 absolute inset-0 transform -rotate-90 m-auto"}
                     style={{ width: (prop.skateWidth) / 6 + "rem" }}
                     onLoad={() => setStickerLoaded(true)}
                  />}


               {(prop.sticker.orientation === undefined) &&
                  <img id={"image" + prop.sticker._id}
                     src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                     alt={prop.sticker.artworkIdentifier}
                     className={(skateLoaded && stickerLoaded)
                        ? "absolute inset-0 m-auto"
                        : "opacity-0 absolute inset-0 m-auto"}
                     style={{ width: (prop.skateWidth) / 2 + "rem" }}
                     onLoad={() => setStickerLoaded(true)}
                  />}


            </div>
         </div>


      );
   }


   return (
      <div className="flex-grow-0 flex-shrink-0" >
         <div id="test" className={(skateLoaded && stickerLoaded)
            ? "relative flex flex-col items-center"
            : "bg-white relative flex flex-col items-center"}>

            <img
               height={167}
               src={SKATEBOARD_VERTICAL} alt="Skateboard"
               style={{ width: prop.skateWidth + "rem" }}
               className={(skateLoaded && stickerLoaded)
                  ? "object-contain"
                  : "opacity-0 object-contain"}
               onLoad={() => setSkateLoaded(true)}
            />

            {(prop.sticker.orientation === "vertical") &&
               <img id={"image" + prop.sticker._id}
                  src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                  alt={prop.sticker.artworkIdentifier}
                  className="absolute inset-0 m-auto"
                  style={{ width: (prop.skateWidth) / 2.6 + "rem" }}
                  onLoad={() => setStickerLoaded(true)}
               />}

            {(prop.sticker.orientation === "square_vertical" || prop.sticker.orientation === "square_horizontal") &&
               <img id={"image" + prop.sticker._id}
                  src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                  alt={prop.sticker.artworkIdentifier}
                  className="absolute inset-0 m-auto"
                  style={{ width: (prop.skateWidth) / 2 + "rem" }}
                  onLoad={() => setStickerLoaded(true)}
               />}

            {(prop.sticker.orientation === "horizontal") &&
               <img id={"image" + prop.sticker._id}
                  src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                  alt={prop.sticker.artworkIdentifier}
                  className={(skateLoaded && stickerLoaded)
                     ? "absolute inset-0 transform -rotate-90 m-auto"
                     : "opacity-0 absolute inset-0 transform -rotate-90 m-auto"}
                  style={{ width: (prop.skateWidth) / 1.6 + "rem" }}
                  onLoad={() => setStickerLoaded(true)}
               />}


            {(prop.sticker.orientation === undefined) &&
               <img id={"image" + prop.sticker._id}
                  src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                  alt={prop.sticker.artworkIdentifier}
                  className={(skateLoaded && stickerLoaded)
                     ? "absolute inset-0 m-auto"
                     : "opacity-0 absolute inset-0 m-auto"}
                  style={{ width: (prop.skateWidth) / 2 + "rem" }}
                  onLoad={() => setStickerLoaded(true)}
               />}


         </div>
      </div>
   )
}




/*import React, { useState } from 'react';
import '../../../styles/main.css';
import SKATEBOARD from '../../../logos/merch/SKATEBOARD.jpg'

const StickerSkateboard = props => {

   const [prop] = useState(props);

   return (
      <div className="relative">
         <img className="object-contain" src={SKATEBOARD} alt="Skateboard" style={{ width: prop.skateWidth + "rem" }}></img>

         {(prop.sticker.orientation === "vertical" || prop.sticker.orientation === "square") && <img id={"image" + prop.sticker._id} src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"} alt={prop.sticker.artworkIdentifier} className="absolute inset-0 m-auto" style={{ width: (prop.skateWidth) / 1.6 + "rem" }} />}

         {prop.sticker.orientation === "horizontal" && <img id={"image" + prop.sticker._id} src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"} alt={prop.sticker.artworkIdentifier} className="absolute inset-0 transform -rotate-90 m-auto" style={{ width: (prop.skateWidth) / 1.6 + "rem" }} />}

         {prop.sticker.orientation === undefined && <img id={"image" + prop.sticker._id} src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"} alt={prop.sticker.artworkIdentifier} className="absolute inset-0 transform m-auto" style={{ width: (prop.skateWidth) / 1.6 + "rem" }} />}

      </div>

   );

}

export default StickerSkateboard;*/


/*
transform -rotate-90
   render() {
    const { title, img, type, price, inCart } = this.props.product;
    return (
      <div className="m-4">
        <div onClick={() => console.log('you clicked on the image container')}>
          <Link to="/details">
            <img src={img} alt="print" className="object-contain h-64" />
          </Link>

          <h1 className="">{type} - {title}</h1>
          <h2 className="">{price} euros</h2>

          <button className="font-bold text-center border-2 border-black px-4 py-1 focus:outline-none focus:bg-black focus:text-white" disabled={inCart ? true : false} onClick={() => console.log("Added to the cart")}>
            {inCart ? (<p disabled> IN CART</p>) : (<p className="hover:text-white">ADD TO CART</p>)}
          </button>
        </div>

      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id:PropTypes.number,
    img:PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    inCart: PropTypes.bool
  }).isRequired
} */