export const resizeBlock = (line, blockWidth, step) => { // resizes the block text so that it looks uniform
   if (line) {
      let width = line.offsetWidth;
      let size = line.style.fontSize;

      while (width > blockWidth) {
         size = (size.substring(0, size.length - 2) - (size.substring(0, size.length - 2) > 20 ? step : step / 4)) + "px";
         width = line.offsetWidth;
         line.style.fontSize = size;
      }
      line.style.opacity = "1";
   }
}