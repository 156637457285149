import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../../styles/main.css';
import ErrorNotice from '../utils/ErrorNotice';
import TRASH from './../../logos/admin/TRASH.svg'
import DeleteConfirmation from "../utils/DeleteConfirmation";
import { SCREEN_HEIGHT } from "../../helpers/constants";

export default function DashboardOrders() {

   const [adminData, setAdminData] = useState();
   const [error, setError] = useState();

   const [ordersList, setOrdersList] = useState([]);

   const [deleteOrderMode, setDeleteOrderMode] = useState(false);
   const [orderIdToDelete, setOrderIdToDelete] = useState("");
   const history = useHistory();

   useEffect(() => {
      const checkLoggedIn = async () => {
         let token = localStorage.getItem("x-auth-token");
         if (token === null) {
            localStorage.setItem("x-auth-token", "");
            token = "";
         }
         const tokenValidRes = await axios.post(
            "https://gasparddeshusses.herokuapp.com/api/admin/tokenIsValid", null, { headers: { "x-auth-token": token } }
         );
         if (tokenValidRes.data) {
            const adminRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/", {
               headers: { "x-auth-token": token },
            });
            setAdminData({ token, admin: adminRes.data });

            const getOrdersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/orders/all", {
               headers: { "x-auth-token": token },
            });

            setOrdersList(getOrdersRes.data);
         }
      };

      checkLoggedIn();
   }, []);

   const sendOrder = async (id, shippedValue) => {
      try {
         if (!shippedValue) {
            let changeIsShipped = { isShipped: !shippedValue }
            await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/orders/update/' + id, changeIsShipped, {
               headers: { "x-auth-token": adminData.token },
            });
            await axios.get('https://gasparddeshusses.herokuapp.com/api/admin/orders/email/shipped/' + id);
            history.go(0)
         }

      } catch (error) {
         console.log(error);
      }
   }

   const getOrderToDelete = id => {
      setDeleteOrderMode(true)
      setOrderIdToDelete(id)
   }

   const deleteOrder = async (id) => {
      try {
         await axios.delete('https://gasparddeshusses.herokuapp.com/api/admin/orders/delete/' + id, {
            headers: { "x-auth-token": adminData.token },
         });
         setDeleteOrderMode(false);
      } catch (error) {
         console.log(error);
      }
   }


   return (
      <div>

         {deleteOrderMode && <DeleteConfirmation delete={() => deleteOrder(orderIdToDelete)} goBack={() => setDeleteOrderMode(false)} type="ORDER" />}

         <div className="w-full flex-col-center">

            <div className="hidden md:block mb-12" style={{ height: 0.12 * SCREEN_HEIGHT }}></div>

            {error && (
               <ErrorNotice message={error} clearError={() => setError(undefined)} />
            )}

            <div className="w-full md:w-2/3 flex flex-col">

               <div className="hidden md:grid bg-white border-b border-black w-80 md:w-2/3 fixed grid-cols-12 py-2 font-bold">
                  <div className="col-span-3 pl-3">Email address</div>
                  <div className="col-span-1">Date</div>
                  <div className="col-span-1">Price</div>
                  <div className="col-span-3">Items</div>
                  <div className="col-span-9 md:col-span-3 pl-2">Address</div>
                  <div className="col-span-3 md:col-span-1 text-center md:pr-8"></div>
               </div>

               <div className="h-112 pb-20 overflow-y-auto border border-black">

                  <div className="hidden md:block md:h-10"></div>

                  {ordersList.map(item =>
                     <div className={item.isShipped
                        ? "w-full bg-green-200 grid grid-cols-12"
                        : "w-full bg-gray-50 grid grid-cols-12"}
                        key={item._id}>

                        <div className="col-span-12 h-2" />

                        <div className="hidden md:flex md:col-span-3 pl-3" onClick={() => console.log(item)}>{item.email}</div>
                        <div className="hidden md:flex md:col-span-1 break-words">{(new Date(item.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }))}</div>
                        <div className="hidden md:flex md:col-span-1 flex-col break-words">
                           <span>{item.totalPrice / 100}€</span>
                           <span className="text-xs">incl. {item.shippingPrice / 100}€</span>
                        </div>
                        <div className="hidden md:block md:col-span-3">{item.orderItems.map(article =>
                           <div key={article._id} className="w-full flex flex-row justify-between items-center">
                              <img id={"img" + article._id} src={"https://gasparddeshusses.com/images/artworks/" + article.artworkIdentifier + ".jpg"}
                                 alt={article.artworkIdentifier} className="h-5 object-cover w-8" />
                              <p className="w-full lowercase truncate ... px-2">{article.type} {article.artworkTitle}</p>
                              <p className="pr-4">x{article.quantity}</p>
                           </div>
                        )}</div>
                        <div className="col-span-9 md:col-span-3 flex flex-col pl-2">
                           <p>{item.shipping.address}</p>
                           <p>{item.shipping.postalCode}, {item.shipping.city}</p>
                        </div>
                        <div className="col-span-3 md:col-span-1 flex-center pr-6">
                           <input type="checkbox" className="form-checkbox h-5 w-5 text-green-600" onChange={() => sendOrder(item._id, item.isShipped)} checked={item.isShipped}></input>

                           <div className="w-2" />

                           <div className="h-5 w-5 hover:opacity-0" onClick={() => getOrderToDelete(item._id)}>
                              <img className="object-contain h-5 w-5" src={TRASH} alt="D"></img>
                           </div>
                        </div>

                        <div className="col-span-12 border-t border-gray-300 mt-2 "></div>


                     </div>)}
               </div>
            </div>

         </div>
      </div>

   );
}
