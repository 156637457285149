import { createContext } from "react";

export default createContext(null);


/*export const DataProvider = ({children}) =>{
   const [adminData, setAdminData] = useState(false)

   useEffect(() => {
      const checkLoggedIn = async () => {
        let token = localStorage.getItem("x-auth-token");
        if (token === null) {
          localStorage.setItem("x-auth-token", "");
          token = "";
        }
        const tokenValidRes = await axios.post(
          "https://gasparddeshusses.herokuapp.com/api/admin/tokenIsValid", null,{ headers: { "x-auth-token": token } }
        );
        if (tokenValidRes.data) {
          const adminRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/", {
            headers: { "x-auth-token": token },
          });
          setAdminData({
            token,
            admin: adminRes.data,
          });
        }
      };
  
      checkLoggedIn();
    }, []);

   
   const state = {
      adminData: [adminData, setAdminData],
   }

   return (
       <AdminContext.Provider value={state}>
           {children}
       </AdminContext.Provider>
   )
}*/
