import React, { useState } from "react";

import ART from '../../logos/landing/ART-CHOMSKY.svg'
import INSTAGRAM from '../../logos/landing/INSTAGRAM.svg'
import MERCH from '../../logos/landing/MERCH.svg'
import INFORMATION from '../../logos/landing/INFORMATION.svg'
import { Link } from "react-router-dom";

export default function NavbarMenu({ location, onClose }) {

   const [preventReturn, setPreventReturn] = useState(false);

   return (
      <div className="z-40 overflow-hidden flex-col-center w-full h-full pb-8 md:pb-0" onClick={preventReturn ? null : () => onClose()}>

         <div className={`w-5/6 md:w-full ${location === "" ? "opacity-100" : "opacity-20 md:opacity-100"} h-auto md:h-80 flex justify-center items-end`}>
            <Link to="/" className={`object-fill w-104  ${location === "" ? "h-full" : "h-full md:h-2/3 md:hover:h-full"}`}>
               <img src={ART} alt="ART" className="md:h-full w-104" />
            </Link>
         </div>

         <div className="h-6 md:h-4"></div>

         <div className={`w-5/6 md:w-full ${location === "information" ? "opacity-100" : "opacity-20 md:opacity-100"} h-auto md:h-12 flex justify-center items-start`}>
            <a href="/information" className="object-fill md:h-full " onMouseEnter={() => setPreventReturn(true)}
               onMouseLeave={() => setPreventReturn(false)}
            >
               <img src={INFORMATION} alt="INFORMATION" className={`object-fill h-full  ${location === "information" ? "w-220" : "w-104 md:hover:w-220"}`} />
            </a>
         </div>


         <div className="h-6 md:h-4"></div>

         <div className={`w-5/6 md:w-full ${location === "merchandising" ? "opacity-100" : "opacity-20 md:opacity-100"} h-auto md:h-22 flex justify-center items-start`}>
            <a href="/merchandising" className="object-fill md:h-full " onMouseEnter={() => setPreventReturn(true)}
               onMouseLeave={() => setPreventReturn(false)}
            >
               <img src={MERCH} alt="MERCH" className={`object-fill h-full  ${location === "merchandising" ? "w-220" : "w-104 md:hover:w-220"}`} />
            </a>
         </div>

         <div className="h-6 md:h-4"></div>

         <div className="w-5/6 md:w-full h-auto md:h-44 flex justify-center items-start opacity-20 md:opacity-100">
            <a href="https://instagram.com/ga5parov" target="_blank" rel="noreferrer" className="object-fill md:h-1/3 w-104 md:hover:h-full" onMouseEnter={() => setPreventReturn(true)}
               onMouseLeave={() => setPreventReturn(false)}
            >
               <img src={INSTAGRAM} alt="INSTAGRAM" className="object-fill w-104 h-full" />
            </a>
         </div>
      </div>
   );
}
