import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../styles/main.css';
import Social from '../views/Social';
import { CountryDropdown } from 'react-country-region-selector';

export default function FreeSticker() {

   const [artworksList, setArtworksList] = useState([]);
   let { handle } = useParams();

   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [address, setAddress] = useState("");
   const [postalCode, setPostalCode] = useState("");
   const [country, setCountry] = useState("");
   const [city, setCity] = useState("");

   const [artworkChosen, setArtworkChosen] = useState("");
   const [confirmation, setConfirmation] = useState(false);
   const [formDisabled, setFormDisabled] = useState(false);

   useEffect(() => {
      const getArtwork = async () => {
         const getArtworksRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/artworks/");
         setArtworksList(getArtworksRes.data);
      };

      getArtwork();
   }, [])


   const handleSubmit = async (event) => {
      event.preventDefault();
      try {
         setFormDisabled(true);
         const newGiveAway = { handle, artworkChosen, firstName, lastName, address, postalCode, city, country };
         await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/orders/giveaway", newGiveAway);
         setConfirmation(true);
      } catch (error) {
         console.log(error.message);
      }
   }

   return (
      <div className="w-full flex-col-center">

         <div className="h-20"></div>

         <div className="sticky top-0 bg-white z-40 h-20 w-full md:h-0"></div>

         {confirmation ? <div className="px-8 md:px-0 flex-none w-full sm:w-104 md:w-160 z-50">
            <p className="text-3xl md:text-5xl font-black">💌 Thanks!</p>

            <div className="h-4" />

            <p className="md:text-xl font-black">We will ship the sticker as soon as we have it in stock.</p>

            <div className="h-6" />

            <div className="w-full grid grid-cols-8">
               <div className="col-span-2 md:col-span-1 pt-0.5 h-8 leading-tight">Name:</div><div className="col-span-6 md:col-span-7 h-8 pt-0.5 pl-2 break-normal leading-tight">{firstName} {lastName}</div>

               <div className="col-span-8 h-2" />

               <div className="col-span-2 md:col-span-1 pt-0.5 h-8 leading-tight">Sticker:</div><div className="col-span-6 md:col-span-7 h-8 pt-0.5 pl-2 break-normal leading-tight uppercase">{artworkChosen}</div>

               <div className="col-span-8 h-2" />

               <div className="col-span-2 md:col-span-1 pr-6 leading-tight pt-1">Address:</div><div className="col-span-6 md:col-span-7 w-full h-40 focus px-2 align-text-top break-normal leading-tight pt-1">{address}, {postalCode}, {city}, {country}</div>
            </div>

         </div> : <div className="z-50 flex-col-center">
               <div className="flex flex-col justify-start items-start px-6">
                  <div className="text-6xl pb-3 pl-4">🔪🌸</div>
                  <h1 className="text-4xl font-bold pl-4">
                     <span className="break-all">@{handle}</span>
                     <span>, share the colors</span>
                  </h1>
               </div>
               <div className="h-4" />

               <form onSubmit={handleSubmit} >
                  <div className="h-3" />

                  <div className="flex justify-center items-start">
                     <div className="text-8xl font-bold pr-5">1</div>
                     <div className="w-64 md:w-80">
                        <div className="pb-2 pt-2">
                           <span className="text-xl font-bold pr-2">Pick your sticker</span>
                           <a href="/stickers" target="_blank" rel="noreferrer" className="hover:opacity-0">(see all)</a>
                        </div>
                        <select className="focus w-64 md:w-80" onChange={(e) => setArtworkChosen(e.target.value)}>
                           {artworksList && artworksList.map(item => <option key={item._id} value={item.title}>{item.title}</option>)}
                        </select>
                     </div>
                  </div>

                  <div className="h-3" />

                  <div className="flex justify-center items-start">
                     <div className="text-8xl font-bold pr-5">2</div>
                     <div className="w-64 md:w-80">
                        <div className="text-xl font-bold pb-2 pt-2">Fill in your shipping details</div>
                        <div className="w-full flex flex-row justify-between items-center">
                           <div className="flex flex-col w-1/2 pr-3">
                              <input required onChange={(e) => setFirstName(e.target.value)} className="h-8 focus  pl-2" value={firstName} placeholder="First name"></input>
                           </div>

                           <div className="flex flex-col w-1/2 pl-3">
                              <input required onChange={(e) => setLastName(e.target.value)} className="h-8 focus pl-2" value={lastName} placeholder="Last name"></input>
                           </div>
                        </div>

                        <div className="h-2" />

                        <div className="flex flex-col">
                           <input required onChange={(e) => setAddress(e.target.value)} className="h-8 focus pl-2" value={address} placeholder="Address"></input>
                        </div>

                        <div className="h-2" />

                        <div className="w-full flex flex-row justify-between items-center">
                           <div className="flex flex-col w-6/12 pr-3">
                              <input required onChange={(e) => setPostalCode(e.target.value)} className="h-8 focus pl-2" value={postalCode} placeholder="Postal code"></input>
                           </div>

                           <div className="flex flex-col w-6/12 pl-3">
                              <input required onChange={(e) => setCity(e.target.value)} className="h-8 focus pl-2" value={city} placeholder="City"></input>
                           </div>
                        </div>

                        <div className="h-2" />

                        <div className="flex flex-col">
                           <CountryDropdown value={country} onChange={(val) => setCountry(val)} className="h-8 focus pl-1" defaultOptionLabel="Country" />
                        </div>

                     </div>

                  </div>

                  <div className="h-5" />

                  <div className="flex justify-center items-start">
                     <div className="text-8xl font-bold pr-5">3</div>
                     <div className="w-64 md:w-80 pt-2">
                        <div className="text-xl font-bold ">Share the colors</div>
                        <p>When you receive the sticker, feel free to share a picture of it on Instagram while tagging @ga5parov.</p>
                     </div>
                  </div>

                  <div className="h-8" />

                  <div className="w-full flex-center">
                     <button id="submitButton" disabled={formDisabled} type="submit" className="text-xl font-bold w-64 py-2 btn-black">{formDisabled ? <span>LOADING...</span> : <span>GET MY STICKER</span>}</button>
                  </div>
               </form>

            </div>}

         <div className="h-32"></div>

         <Social />

         <div className="h-8" />

      </div>
   );
}



/*
<div className="flex justify-center items-start">
                     <div className="text-8xl pr-5">1</div>
                     <div className="text-xl font-bold w-64 md:w-80 pt-2">
                        <div>Follow Gaspard</div>
                        <div className="h-2" />
                        <a href="https://instagram.com/ga5parov" target="_blank" rel="noreferrer">
                           <div className="text-center text-xl font-bold w-40 py-1 btn-black">@ga5parov</div>
                        </a>
                     </div>
                  </div>

                  <div className="h-3" />
*/