import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../../styles/main.css';
import AmexLogo from './../../logos/merch/amex.svg'
import VisaLogo from './../../logos/merch/visa.svg'
import MastercardLogo from './../../logos/merch/mastercard.svg'
import CartContext from "./../../context/CartContext";
import { CountryDropdown } from 'react-country-region-selector';
import WARNING from './../../logos/merch/warning.png'

// STRIPE
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

export default function Cart() {

   const [loaded, setLoaded] = useState(true);
   const [popupLoaded, setPopupLoaded] = useState(false);
   const [shippingWarning, setShippingWarning] = useState(false);
   const history = useHistory();

   // ----------- ORDERS VARIABLES ----------- //
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [country, setCountry] = useState("");
   const [address, setAddress] = useState("");
   const [postalCode, setPostalCode] = useState("");
   const [city, setCity] = useState("");
   const [termsRead, setTermsRead] = useState(false);
   const [comment, setComment] = useState("");
   const [itemsPrice, setItemsPrice] = useState(0);
   const [taxPrice] = useState(0);
   const [shippingPrice, setShippingPrice] = useState(0);
   const [totalPrice, setTotalPrice] = useState(0);

   // ----------- STRIPE SETUP ----------- //
   const stripe = useStripe();
   const elements = useElements();
   const [formDisabled, setFormDisabled] = useState(false);
   const [loadingPayment, setLoadingPayment] = useState(false);
   const CARD_OPTIONS = {
      iconStyle: 'solid',
      style: {
         empty: {
            iconColor: '#000',
            color: '#000',
         },
         base: {
            iconColor: '#000000',
            color: 'black',
            fontWeight: 400,
            fontFamily: ' "Helvetica Neue", Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#fce883' },
         },
         invalid: {
            iconColor: '#ff4c4c',
            color: '#ff4c4c',
         },
         complete: {
            iconColor: '#24E39E',
            color: 'black'
         }
      },
   };

   // ----------- HANDLE ORDER SUBMISSION ----------- //
   const handleSubmit = async (event) => {
      event.preventDefault();

      if (items.length !== 0) {
         setFormDisabled(true);

         const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
         })

         if (!error) {
            setLoadingPayment(true);
            const { id } = paymentMethod;
            try {
               // pass up a product a product id and quantity to check on the backend
               const newOrder = {
                  name: firstName + " " + lastName,
                  first_name: firstName,
                  last_name: lastName,
                  email,
                  orderItems: items,
                  shipping: { address, city, postalCode, country },
                  payment: { paymentMethod: "Stripe" },
                  itemsPrice,
                  taxPrice,
                  shippingPrice,
                  totalPrice: 20,
                  comment,
                  isPaid: false,
                  paidAt: "",
                  isDelivered: false,
                  deliveredAt: ""
               };

               const orderRes = await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/orders/add", newOrder);

               const { data } = await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/orders/charge", { id, amount: orderRes.data.pricePaid, orderId: orderRes.data._id });

               if (data.payment.status === "requires_action") {
                  localStorage.setItem("stripe_client_secret", data.payment.client_secret)
                  localStorage.setItem("order_id", orderRes.data._id)
                  var iframe = document.createElement('iframe');
                  iframe.classList.add("absolute", "top-0", "z-50")
                  iframe.id = "stripe_popup"
                  iframe.src = data.payment.next_action.redirect_to_url.url;
                  iframe.width = window.innerWidth;
                  iframe.height = window.innerHeight;
                  document.querySelector("#container").appendChild(iframe);
                  window.scrollTo(0, 0);
                  setLoadingPayment(false);
                  setPopupLoaded(true);

               } else if (data.payment.status === "succeeded") {
                  await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/orders/update/' + orderRes.data._id, { isPaid: true });
                  await axios.get('https://gasparddeshusses.herokuapp.com/api/admin/orders/email/confirmation/' + orderRes.data._id)
                  setItems([]);
                  setNumberItemsCart(0);
                  localStorage.removeItem('items');
                  history.push("/order/confirmation/" + orderRes.data._id);
               } else {
                  console.log(data.payment.status)
               }
            } catch (error) {
               console.log(error);
            }
         }
      }
   }

   // ----------- HANDLE 3DS RESULT ----------- //
   const on3DSComplete = async (stripe_client_secret) => {
      // Hide the 3DS UI
      document.querySelector("#container").removeChild(document.querySelector("#stripe_popup"))

      // Check the PaymentIntent
      try {
         const threeds_res = await stripe.retrievePaymentIntent(stripe_client_secret)
         if (threeds_res.error) {
            // PaymentIntent client secret was invalid
            console.log(threeds_res.error)
         } else {
            if (threeds_res.paymentIntent.status === 'succeeded') {
               const order_id = localStorage.getItem("order_id");
               await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/orders/update/' + order_id, { isPaid: true });
               await axios.get('https://gasparddeshusses.herokuapp.com/api/admin/orders/email/confirmation/' + order_id);
               setItems([]);
               setNumberItemsCart(0);
               localStorage.removeItem('items');
               history.push("/order/confirmation/" + order_id);

            } else if (threeds_res.paymentIntent.status === 'requires_payment_method') {
               const order_id = localStorage.getItem("order_id");
               console.log("3DS failed")
               history.push("/order/error/" + order_id);
            }
         }
      } catch (error) {
         console.log(error)
      }

   }

   useEffect(() => {
      const handler = event => {
         if (event.data === "3DS-authentication-complete") {
            const stripe_client_secret = localStorage.getItem("stripe_client_secret");
            on3DSComplete(stripe_client_secret);
         }
      }

      window.addEventListener("message", handler)

      return () => window.removeEventListener("message", handler)
   }, [])

   // ----------- ITEMS IN CART ----------- //
   const [items, setItems] = useState([]);
   const { setNumberItemsCart } = useContext(CartContext);
   let verifiedShippingPrice = 200;

   function updateCart(items) {
      setItems(items);
      setNumberItemsCart(items.reduce((a, c) => a + c.quantity, 0, 0))

      for (let i = 0; i < items.length; i++) {
         if (items[i].type === "poster" || "t-shirt") {
            verifiedShippingPrice = 400;
            break;
         }
      }

      if (country === "France" || country === "Luxembourg" || country === "Germany" || country === "Belgium" || country === "") {
         setItemsPrice(items.reduce((a, c) => a + c.price * c.quantity, 0, 0));
         setShippingPrice(verifiedShippingPrice);
         setTotalPrice(items.reduce((a, c) => a + c.price * c.quantity, 0, 0) + verifiedShippingPrice);
      } else {
         setItemsPrice(items.reduce((a, c) => a + c.price * c.quantity, 0, 0));
         setShippingPrice(verifiedShippingPrice + 100);
         setTotalPrice(items.reduce((a, c) => a + c.price * c.quantity, 0, 0) + verifiedShippingPrice + 100);
      }
   }

   useEffect(() => {
      const items = JSON.parse(localStorage.getItem('items'));
      if (items) {
         updateCart(items)
      }

   }, [country]);

   function decrementQuantity(x) {
      let cartCopy = [...items];
      if (x.quantity !== 1) {
         x.quantity -= 1;
         updateCart(cartCopy);
         let stringCart = JSON.stringify(cartCopy);
         localStorage.setItem('items', stringCart);
      } else {
         cartCopy = cartCopy.filter(item => item._id !== x._id);
         updateCart(cartCopy);
         let stringCart = JSON.stringify(cartCopy);
         localStorage.setItem('items', stringCart)
      }
   }

   function incrementQuantity(x) {
      let cartCopy = [...items];
      if (x.type !== "original") {
         x.quantity += 1;
      }
      updateCart(cartCopy);
      let stringCart = JSON.stringify(cartCopy);
      localStorage.setItem('items', stringCart)
   }


   return (
      <>
         {(loadingPayment && window.innerWidth > 768) && <div className="absolute top-0 cursor-wait z-50 w-full h-screen"></div>}

         <div id="container" className={(loaded)
            ? "md:h-screen w-full flex-col-center"
            : "md:h-screen w-full flex-col-center opacity-0"} onLoad={() => setLoaded(true)}>
            <form onSubmit={handleSubmit} className={popupLoaded ? "opacity-0" : "opacity-100"}>

               <div className="w-full flex flex-col md:flex-row justify-center items-center md:items-start">

                  <div className="h-18 w-full"></div>

                  <div className="sticky top-0 bg-white z-20 h-18 w-full md:h-0"></div>

                  <div className="w-80 sm:w-104 flex-col-center border border-gray-300 md:mr-4 py-4 px-6 z-20">
                     <div className="mb-4 text-3xl font-bold self-start">SHIPPING</div>

                     <div className="border-t border-gray-300 w-full pb-4"></div>

                     <div className="w-full flex flex-col">
                        <div className="w-full flex flex-row justify-between items-center">
                           <div className="flex flex-col w-1/2 pr-3">
                              <label className="text-sm font-bold">First name <span className="font-black text-red-600">*</span> </label>
                              <input required onChange={(e) => setFirstName(e.target.value)} className="h-8 focus  pl-2" value={firstName}></input>
                           </div>

                           <div className="flex flex-col w-1/2 pl-3">
                              <label className="text-sm font-bold">Last name <span className="font-black text-red-600">*</span> </label>
                              <input required onChange={(e) => setLastName(e.target.value)} className="h-8 focus pl-2" value={lastName}></input>
                           </div>
                        </div>

                        <div className="h-2"></div>

                        <div className="flex flex-col">
                           <label className="text-sm font-bold">Email address <span className="font-black text-red-600">*</span> </label>
                           <input required onChange={(e) => setEmail(e.target.value)} className="h-8 focus pl-2" value={email}></input>
                        </div>

                        <div className="h-2"></div>

                        <div className="flex flex-col">
                           <label className="text-sm font-bold">Country/region <span className="font-black text-red-600">*</span> </label>
                           <CountryDropdown value={country} onChange={(val) => setCountry(val)} className="h-8 focus pl-1" defaultOptionLabel="" />
                        </div>

                        <div className="flex flex-col">
                           <label className="text-sm font-bold">Address <span className="font-black text-red-600">*</span> </label>
                           <input required onChange={(e) => setAddress(e.target.value)} className="h-8 focus pl-2" value={address}></input>
                        </div>

                        <div className="h-2"></div>


                        <div className="w-full flex flex-row justify-between items-center">
                           <div className="flex flex-col w-5/12 pr-3">
                              <label className="text-sm font-bold">Postal code <span className="font-black text-red-600">*</span> </label>
                              <input required onChange={(e) => setPostalCode(e.target.value)} className="h-8 focus pl-2" value={postalCode}></input>
                           </div>

                           <div className="flex flex-col w-7/12 pl-3">
                              <label className="text-sm font-bold">City <span className="font-black text-red-600">*</span> </label>
                              <input required onChange={(e) => setCity(e.target.value)} className="h-8 focus pl-2" value={city}></input>
                           </div>
                        </div>

                        <div className="h-2"></div>

                        <div className="flex flex-col justify-start">
                           <label className="text-sm font-bold">Optional comment</label>
                           <textarea onChange={(e) => setComment(e.target.value)} className="w-full focus px-2 align-text-top break-normal leading-tight pt-1" value={comment}></textarea>
                        </div>

                        <div className="h-4"></div>

                        <div className="flex-center">
                           <input type="checkbox" required onChange={(e) => setTermsRead(e.target.value)} className="appearance-none w-4 h-4 flex-none checked:bg-black focus:outline-none" value={termsRead}></input>

                           <label className="w-64 md:w-80 flex-none pl-3 text-sm">By clicking, I acknowledge that I have read and I agree to the website's <a className="font-bold underline md:hover:opacity-0" href="https://gasparddeshusses.com/terms-and-conditions" target="_blank" rel="noreferrer">terms and conditions</a><span className="font-black text-red-600"> *</span></label>
                        </div>

                     </div>
                  </div>

                  <div className="h-8 w-full md:hidden"></div>

                  <div className="flex-col-center md:mr-4 px-6">

                     <div className="w-80 sm:w-104 flex-col-center border border-gray-300 md:mr-4 py-4 px-6 z-20 select-none">
                        <div className="mb-4 text-3xl font-bold self-start">ORDER</div>

                        <div className="border-t border-gray-300 w-full pb-4"></div>


                        {(items.length !== 0) ? <div className="w-full">{items.map((x, index) =>
                           <div key={x._id} className="w-full grid grid-cols-12 font-bold">

                              <div className="sm:pl-4 col-span-2 pb-2 flex flex-row justify-end items-center">
                                 <img src={"images/artworks/" + x.artworkIdentifier + ".jpg"} alt={x.artworkIdentifier} className="h-10 object-contain"></img>
                              </div>

                              {x.size && <div className="col-span-5 h-10 flex items-center leading-3 text-xs break-words sm:break-normal font-normal italic pl-3 uppercase hover:font-medium" onClick={() => history.push("/clothing/" + x.artworkIdentifier)}>{x.type}<br />{x.artworkTitle} ({x.size.toString()})</div>}

                              {(x.size === undefined) && <div className="col-span-5 h-10 flex items-center leading-3 text-xs break-words sm:break-normal font-normal italic pl-3 uppercase hover:font-medium" onClick={() => history.push("/artworks/" + x.artworkIdentifier)}>{x.type}<br />{x.artworkTitle}</div>}


                              <div className="bg-white col-span-3 h-10 items-center font-normal leading-tight sm:pl-4 text-center flex justify-center select-none">
                                 <div className="flex flex-row h-6 border border-gray-300">
                                    <p className="h-6 w-5 text-xl font-bold leading-4 focus:outline-none" onClick={() => decrementQuantity(x)}>-</p>
                                    <p className="h-4 w-5 font-bold leading-5 focus:outline-none" onClick={() => decrementQuantity(x)}>{x.quantity}</p>
                                    <p className="h-6 w-5 text-xl font-bold leading-4 focus:outline-none" onClick={() => incrementQuantity(x)}>+</p>
                                 </div>
                              </div>
                              <div className="col-span-2 h-10 flex items-center justify-end text-right sm:pr-4">{((x.price) * (x.quantity)) / 100}€</div>
                           </div>
                        )}</div> : <p className="text-xs font-normal pb-2">EMPTY CART</p>}


                        <div className="w-full grid grid-cols-2 font-bold">
                           <div className="col-span-2 border-t border-gray-300 my-2"></div>

                           <div className="sm:pl-4">Subtotal</div>
                           <div className="sm:pr-4 text-right">{itemsPrice / 100}€</div>

                           <div className="col-span-2 border-t border-gray-300 my-2"></div>

                           {window.innerWidth > 768 ? <div className="relative sm:pl-4 flex justify-start items-center">
                              <span>Shipping</span>
                              <span onMouseEnter={() => setShippingWarning(true)} onMouseLeave={() => setShippingWarning(false)}>
                                 <img src={WARNING} className="pl-2 h-5 object-contain" />
                              </span>
                              {shippingWarning && <div className="absolute ml-28 top-0 w-64 px-4 py-2 bg-yellow-100 rounded-lg text-sm font-normal">
                                 Due to the way we currently handle our inventory, delivery time can take up to 3 weeks. Thank you for your understanding, we'll do our very best!
                              </div>}
                           </div> : <div className="relative sm:pl-4 flex justify-start items-center">
                              <span>Shipping</span>
                              <span onClick={() => setShippingWarning(!shippingWarning)}>
                                 <img src={WARNING} className="pl-2 h-5 object-contain" />
                              </span>
                              {shippingWarning && <div className="absolute mt-10 top-0 w-64 px-4 py-2 bg-yellow-100 rounded-lg text-sm font-normal">
                                 Due to the way we currently handle our inventory, delivery time can take up to 3 weeks. Thank you for your understanding, we'll do our very best!
                              </div>}

                           </div>}




                           <div className="sm:pr-4 text-right">{shippingPrice / 100}€</div>

                           <div className="col-span-2 border-t border-gray-300 my-2"></div>

                           <div className="sm:pl-4">Total</div>
                           <div className="sm:pr-4 text-right">{totalPrice / 100}€</div>
                        </div>
                     </div>


                     <div className="h-8 w-full"></div>

                     <div className="flex-col-center text-xl text-center">
                        <div className="w-80 sm:w-104 flex-col-center border border-gray-300 md:mr-4 px-6">

                           <div className="w-full flex flex-row justify-between items-center font-bold py-4">
                              <p className="text-3xl">PAYMENT</p>
                              <div className="flex flex-row">
                                 <img src={AmexLogo} alt="AMEX" className="h-4 md:h-6 px-1" />
                                 <img src={VisaLogo} alt="VISA" className="h-4 md:h-6 px-1" />
                                 <img src={MastercardLogo} alt="MASTERCARD" className="h-4 md:h-6 px-1" />
                              </div>
                           </div>

                           <CardElement required options={CARD_OPTIONS} className="w-full py-2 pl-2 border border-black" onChange={() => setFormDisabled(false)} />

                           <div className="h-6"></div>

                           <button id="checkoutbutton" type="submit" disabled={!stripe || formDisabled} className="text-xl font-bold w-64 py-2 btn-black">

                              {loadingPayment ? <span className="text-base inline-block">PROCESSING PAYMENT...</span> : "ORDER NOW"}
                           </button>

                           <div className="h-6"></div>
                        </div>

                     </div>
                  </div>

                  <div className="h-32 w-full md:hidden"></div>



               </div>
            </form>
         </div>
      </>



   );
}

