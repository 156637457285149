import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../../styles/main.css';
import AdminContext from '../../context/AdminContext';
import ErrorNotice from '../utils/ErrorNotice';

export default function Login(props) {
   const [loading, setLoading] = useState(true);
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [error, setError] = useState();

   const { adminData, setAdminData } = useContext(AdminContext);
   const history = useHistory();

   const submit = async (e) => {
      try {
         e.preventDefault();
         const loginAdmin = { email, password };
         const loginRes = await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/login", loginAdmin);
         setAdminData({
            token: loginRes.data.token,
            admin: loginRes.data.admin,
         });
         localStorage.setItem("x-auth-token", loginRes.data.token);
         history.push("/admin/dashboard/artworks");
      } catch (error) {
         console.log(error.response.data.error);
      }
   };


   useEffect(() => {
      let isMounted = true;
      const { from } = props.location.state || { from: { pathname: '/' } };

      if (adminData.admin) {
         props.history.push(from.pathname);
      } else {
         setLoading(false);
      }

      // setTimeout(() => setLoading(false), 1000)
      return () => isMounted = false;
   });


   return (
      <div className="flex flex-col h-screen justify-center items-center">
         {(!loading) ? <div className="flex flex-col h-screen w-full justify-center items-center">
            <div className="text-5xl md:text-9xl font-bold text-center">LOGIN PAGE</div>
            {error && (
               <ErrorNotice message={error} clearError={() => setError(undefined)} />
            )}
            <form onSubmit={submit} className="w-full md:w-1/2 flex-col-center text-2xl pt-6">
               <input type="email" required onChange={(e) => setEmail(e.target.value)} className="w-2/3 py-2 focus pl-4" placeholder="ADMIN EMAIL"></input>

               <div className="h-2"></div>

               <input type="password" required onChange={(e) => setPassword(e.target.value)} className="w-2/3 py-2 focus pl-4" placeholder="ADMIN PASSWORD"></input>

               <div className="h-8"></div>

               <div className="text-center">
                  <input type="submit" value="LOGIN" className="text-6xl px-16 py-4 hover:text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-black"></input>
               </div>

            </form></div> : <div></div>}
      </div>
   );
}