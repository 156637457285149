import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../styles/main.css';
import OriginalTextBlock from "./OriginalTextBlock"

// Lazyload
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { SCREEN_HEIGHT, SMALL_DEVICE } from '../../helpers/constants';

export default function OriginalDetails() {
   const [originalFound, setOriginalFound] = useState();

   let { identifier } = useParams();

   useEffect(() => {

      const getOriginal = async () => {
         const getOriginalRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/original/" + identifier);

         setOriginalFound(getOriginalRes.data);
      };

      getOriginal();
   }, [identifier]);

   const [inheritedWidth, setInheritedWidth] = useState();
   const [inheritedHeight, setInheritedHeight] = useState();

   if (!originalFound) {
      return null;
   }


   if (SMALL_DEVICE) {
      return <div className="flex-grow-0 flex-shrink-0 w-screen flex-col-center" style={{ height: 0.88 * SCREEN_HEIGHT, marginTop: 0.12 * SCREEN_HEIGHT }}>

         <div className="px-4 py-4 h-1/2 w-full flex flex-center" >
            <img src={"https://gasparddeshusses.com/images/artworks/" + originalFound.artworkIdentifier + ".jpg"}
               alt={"images/artworks/" + originalFound.artworkIdentifier + ".jpg"}
               className="h-full flex object-contain"></img>
         </div>


         <div className="h-1/2 w-full whitespace-no-wrap" style={{ maxWidth: "100vw" }} >
            <OriginalTextBlock original={originalFound} width={window.innerWidth / 1.8} />

         </div>

      </div>;
   }

   return <div className="w-full h-screen flex-center">

      <div className="w-7/12 flex-center">
         <LazyLoadImage
            id={"img" + originalFound._id}
            threshold={2000}
            src={"https://gasparddeshusses.com/images/artworks/" + originalFound.artworkIdentifier + ".jpg"}
            alt={"images/artworks/" + originalFound.artworkIdentifier + ".jpg"}
            className="relative max-h-120 z-20 flex object-contain"
            onLoad={(e) => { setInheritedWidth(e.target.offsetWidth); setInheritedHeight(e.target.offsetHeight) }} />
      </div>


      <div className="justify-center ml-12">
         <OriginalTextBlock original={originalFound} width={370} />
      </div>
   </div>;
}
