import React, { useContext, useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import AdminContext from '../../context/AdminContext';

export default function PrivateRoute({ component: Component, ...rest }) {
   const { adminData } = useContext(AdminContext);

  return (
    <Route
      {...rest}
      render={props => adminData.admin ? ( <Component {...props} /> ) : ( <Redirect to={{ pathname: "/admin/login", state: { from: props.location } }} /> )}
    />
  );
}