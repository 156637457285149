import React, { useState, useEffect, useContext, useRef } from "react";
import axios from 'axios';
import '../../styles/main.css';
import Artwork from './Artwork';
import { SCREEN_WIDTH, SMALL_DEVICE } from "../../helpers/constants";
import { Context as UserContext } from "../../context/UserContext";
import { useHistory, useLocation } from "react-router-dom";


export default function ComboList() {

   const [artworksList, setArtworksList] = useState([]);
   const [sectionLoadedTimeout, setSectionLoadedTimeout] = useState(false);

   const { state: userState, updateArtworks } = useContext(UserContext);
   const lastSeenArtworkRef = useRef();
   const test = useRef();
   const { pathname } = useLocation();

   useEffect(() => {
      if (!userState?.lastArtwork) {
         setSectionLoadedTimeout(true);
         return;
      }

      if (SMALL_DEVICE) {
         setTimeout(() => {
            document.getElementById(userState.lastArtwork).scrollIntoView(true);
            setSectionLoadedTimeout(true);
         }, 1000);
         return;
      }

      if (!SMALL_DEVICE) {
         setTimeout(() => {
            document.getElementById(userState.lastArtwork).scrollIntoView({ align: "start", block: "start" });
            setSectionLoadedTimeout(true);
         }, 1000);
      }



   }, [pathname, lastSeenArtworkRef]);


   useEffect(() => {
      let isSubscribed = true;
      const getArtworks = async () => {
         const getArtworksRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/artworks/");

         setArtworksList(getArtworksRes.data);
         updateArtworks(getArtworksRes.data);
      };


      if (isSubscribed) {
         if (userState?.artworks?.length > 0) {
            setArtworksList(userState.artworks);
            return;
         }

         getArtworks();
      }

      return () => isSubscribed = false;
   }, []);

   function horizontalScroll(e) {
      var container = document.getElementById('container');
      var containerScrollPosition = document.getElementById('container').scrollLeft

      container.scrollTo({
         top: 0,
         left: containerScrollPosition + e.deltaY,
         behaviour: 'smooth' //if you want smooth scrolling
      })
   }


   if (SMALL_DEVICE) {
      return <div >
         <div className="w-full h-36"></div>

         <section className="relative flex flex-col items-center overflow-x-hidden overflow-y-auto">

            {sectionLoadedTimeout
               ? null
               : <div className="fixed top-0 pt-36 bg-white z-40 overflow-x-hidden overflow-y-hidden pointer-events-none">
                  <div className="w-full relative mb-20 z-10 bg-gray-200" style={{ width: SCREEN_WIDTH - 40, height: (SCREEN_WIDTH - 40) * 5 / 4 }}>
                  </div>

                  <div className="w-full relative mb-20 z-10 bg-gray-200" style={{ width: SCREEN_WIDTH - 40, height: (SCREEN_WIDTH - 40) * 4 / 3 }}>
                  </div>
               </div>}

            {artworksList.slice(0).map(item =>
               <div
                  id={item.identifier}
                  key={item._id}
                  ref={userState?.lastArtwork === item.identifier ? lastSeenArtworkRef : null}>
                  <Artwork
                     artwork={item}
                  />
               </div>

            )}

         </section>

         <div className="w-full h-36"></div>
      </div>;
   }

   return <section
      ref={test}
      id="container"
      className="flex-nowrap overflow-x-auto overflow-y-hidden h-screen flex flex-row items-center z-40"
      onWheel={horizontalScroll}
   >

      <div className="flex-grow-0 flex-shrink-0 w-36"></div>

      {sectionLoadedTimeout
         ? null
         : <div className="h-screen bg-white overflow-x-hidden overflow-y-hidden  flex-grow-0 flex-shrink-0 flex flex-row items-center fixed top-0 left-0 z-40 pl-36">
            <div className='w-88 flex-grow-0 flex-shrink-0 mx-18  bg-gray-200' style={{ width: 448 * 5 / 4, height: 448 }}></div>

            <div className='flex-grow-0 flex-shrink-0 mx-18  bg-gray-200' style={{ width: 448 * 5 / 4, height: 448 }}></div>

            <div className='flex-grow-0 flex-shrink-0 mx-18  bg-gray-200' style={{ width: 448 * 5 / 4, height: 448 }}></div>
         </div>}

      {artworksList.slice(0).map((item, index) =>
         <div
            key={item._id}
            id={item.identifier}
            ref={userState?.lastArtwork === item.identifier ? lastSeenArtworkRef : null} className="flex-grow-0 flex-shrink-0"
         >
            <Artwork
               artwork={item} />
         </div>

      )}

      <div className="w-112 flex-grow-0 flex-shrink-0 text-white">FIN</div>
   </section>;
}
