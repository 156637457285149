import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/main.css';

export default function OrderConfirmation() {
   const [orderFound, setOrderFound] = useState("");
   const [loaded, setLoaded] = useState(false);

   let { id } = useParams();

   useEffect(() => {
      let isSubscribed = true;
      const getOrder = async () => {
         const getOrdersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/orders/get/" + id);

         if (isSubscribed) {
            setOrderFound(getOrdersRes.data);
            console.log(getOrdersRes.data)
         }
      };

      getOrder();
      return () => isSubscribed = false

   }, []);




   return (
      <div className={loaded
         ? "flex flex-col w-full md:h-screen justify-start items-center"
         : "flex flex-col w-full md:h-screen justify-start items-center opacity-0"} onLoad={() => setLoaded(true)}>

         {orderFound && <div className="md:h-screen flex flex-col md:flex-row justify-center items-center w-full">

            <div className="w-28 h-28"></div>

            <div className="px-8 flex-none w-full md:px-12 sm:w-104 md:w-160 flex flex-col justify-center items-start">
               <p className="text-3xl md:text-4xl font-black md:pl-2">💳 Card denied</p>

               <div className="h-4"></div>

               <p className="md:text-xl font-black md:pl-2">It seems the card you provided either has insufficient funds, or was not accepted by our banking provider.</p>

               <div className="h-3"></div>

               <p className="md:pl-2">We recommend you try again with another credit card. Sorry for the inconvenience!</p>

               <div className="h-3"></div>

               <Link to="/cart" className="self-center btn-black w-64 text-xl font-bold py-2 md:ml-2 text-center cursor-default">TRY AGAIN</Link>

            </div>

            </div>}

      </div>
   );
}
