import React, { useState, useEffect } from 'react';
import '../../styles/main.css';
import ComboTextBlock from './ComboTextBlock';

// Lazyload
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { SCREEN_WIDTH, SMALL_DEVICE } from '../../helpers/constants';

export default function Artwork(prop) {

   const [artworkOnDisplay, setArtworkOnDisplay] = useState(true);
   const [inheritedWidth, setInheritedWidth] = useState();
   const [inheritedHeight, setInheritedHeight] = useState();
   const [lazyHeight, setLazyHeight] = useState(0)

   useEffect(() => {
      let mounted = true
      if (mounted) {
         // 40 = padding right + padding left
         setLazyHeight(Math.round((window.innerWidth - 40) / (prop.artwork.actualWidth / prop.artwork.actualHeight)))
      }
      return () => mounted = false;
   }, [prop.artwork.actualWidth, prop.artwork.actualHeight])


   if (SMALL_DEVICE) {
      return <div className="w-full relative mb-20 z-10" onClick={() => setArtworkOnDisplay(!artworkOnDisplay)}
         style={{ width: SCREEN_WIDTH - 40, height: (SCREEN_WIDTH - 40) * parseInt(prop.artwork.actualHeight) / parseInt(prop.artwork.actualWidth) }}>

         <LazyLoadImage
            height={(SCREEN_WIDTH - 40) * parseInt(prop.artwork.actualHeight) / parseInt(prop.artwork.actualWidth)}
            id={"img" + prop.artwork._id}
            src={"https://gasparddeshusses.com/images/artworks/" + prop.artwork.identifier + ".jpg"}
            alt={"images/artworks/" + prop.artwork.identifier + ".jpg"}
            className={(artworkOnDisplay && inheritedHeight)
               ? "relative flex z-20 object-contain bg-black"
               : "relative z-10 flex object-contain bg-black"}
            onLoad={(e) => { setInheritedWidth(e.target.offsetWidth); setInheritedHeight(e.target.offsetHeight + 1) }}
         />

         {!inheritedHeight
            ? <div className='bg-gray-200 absolute top-0 z-30' style={{ width: SCREEN_WIDTH - 40, height: (SCREEN_WIDTH - 40) * parseInt(prop.artwork.actualHeight) / parseInt(prop.artwork.actualWidth) }}>
            </div>
            : null}

         {(inheritedWidth && inheritedHeight) && <div id={"infocontainer" + prop.artwork._id}
            className={artworkOnDisplay
               ? "opacity-0 z-10 object-contain absolute top-0 whitespace-no-wrap text-3xl flex-center bg-white"
               : "md:h-112 z-20 object-contain  absolute top-0 whitespace-no-wrap text-3xl flex-center bg-white"}
            style={{ width: inheritedWidth, height: inheritedHeight }}>

            <ComboTextBlock artwork={prop.artwork} width={inheritedWidth} />

         </div>}
      </div>;
   }

   return <div className="relative flex-grow-0 flex-shrink-0 mx-18 flex-center select-none bg-gray-200"
      onMouseEnter={() => setArtworkOnDisplay(false)}
      onMouseLeave={() => setArtworkOnDisplay(true)}
   >

      {!inheritedWidth
         ? <div className='bg-gray-200 absolute top-0 z-30' style={{ width: 448 * parseInt(prop.artwork.actualWidth) / parseInt(prop.artwork.actualHeight), height: 448 }}>
         </div>
         : null}


      <LazyLoadImage
         id={"img" + prop.artwork._id}
         width={448 * parseInt(prop.artwork.actualWidth) / parseInt(prop.artwork.actualHeight)}
         threshold={2000}
         src={"https://gasparddeshusses.com/images/artworks/" + prop.artwork.identifier + ".jpg"}
         alt={"images/artworks/" + prop.artwork.identifier + ".jpg"}
         className={(artworkOnDisplay && inheritedHeight)
            ? "relative z-20 flex md:h-112 object-contain"
            : "opacity-0 z-10 relative flex md:h-112 object-contain"}
         onLoad={(e) => { setInheritedWidth(e.target.offsetWidth); setInheritedHeight(e.target.offsetHeight + 1) }}
      />

      {(inheritedWidth && inheritedHeight) && <div id={"infocontainer" + prop.artwork._id}
         className={artworkOnDisplay
            ? "opacity-0 z-10 md:h-112 object-contain parent absolute top-0 left-0 whitespace-no-wrap text-3xl flex-center bg-white"
            : "md:h-112 z-20 object-contain parent absolute top-0 left-0 whitespace-no-wrap text-3xl flex-center bg-white"}
         style={{ width: 448 * parseInt(prop.artwork.actualWidth) / parseInt(prop.artwork.actualHeight), height: 448 }}>

         <ComboTextBlock artwork={prop.artwork} width={inheritedWidth} />

      </div>}

   </div>;
}