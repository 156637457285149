import './styles/main.css';
import React, { useState, useEffect, useMemo } from "react";
import axios from 'axios';
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';



// ADMIN
import Login from './components/admin/Login';
import DashboardArtworks from './components/admin/DashboardArtworks';
import DashboardProducts from './components/admin/DashboardProducts';
import DashboardEmails from './components/admin/DashboardEmails';
import DashboardOrders from './components/admin/DashboardOrders';

// CART
import Cart from './components/cart/Cart';
import OrderRedirect from './components/cart/OrderRedirect';
import OrderConfirmation from './components/cart/OrderConfirmation';
import OrderError from './components/cart/OrderError';
import TermsAndConditions from './components/cart/TermsAndConditions';
import Legal from './components/views/Legal';


// NAVIGATION
import Navbar from './components/views/Navbar';
import Info from './components/views/Info';
import WitchesAreSaints from './components/views/WitchesAreSaints';

import ContactForm from './components/views/ContactForm';
import Default from './components/views/Default';

// PRODUCTS
import PostersList from './components/products/posters/PostersList';
import PosterDetails from './components/products/posters/PosterDetails';
import StickersList from './components/products/stickers/StickersList';
import StickerDetails from './components/products/stickers/StickerDetails';
import ClothesList from './components/products/clothes/ClothesList';
import ClotheDetails from './components/products/clothes/ClotheDetails';
import ShopList from './components/products/all/ShopList'




// MARKETING
import FreeSticker from './components/marketing/FreeSticker'

// UTILS
import PrivateRoute from './components/utils/PrivateRoute';

// CONTEXT
import AdminContext from './context/AdminContext';
import CartContext from './context/CartContext';
import { Context as UserContext, Provider as UserProvider } from './context/UserContext';

// STRIPE
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


// COMBO
import ComboList from './components/combo/ComboList';
import ComboDetails from './components/combo/ComboDetails';
import Merchandising from './components/products/combo/Merchandising';
import Landing from './components/views/Landing';
import DashboardDesign from './components/admin/DashboardDesign';
import DashboardGlobal from './components/admin/DashboardGlobal';


export default function App() {

   const [adminData, setAdminData] = useState({
      token: undefined,
      admin: undefined
   })
   const [numberItemsCart, setNumberItemsCart] = useState(0);

   const adminContext = useMemo(() => ({ adminData, setAdminData }), [adminData, setAdminData])
   const cartContext = useMemo(() => ({ numberItemsCart, setNumberItemsCart }), [numberItemsCart, setNumberItemsCart])


   useEffect(() => {
      const checkLoggedIn = async () => {
         let token = localStorage.getItem("x-auth-token");
         if (token === null) {
            localStorage.setItem("x-auth-token", "");
            token = "";
         }
         const tokenValidRes = await axios.post(
            "https://gasparddeshusses.herokuapp.com/api/admin/tokenIsValid", null, { headers: { "x-auth-token": token } }
         );
         if (tokenValidRes.data) {
            const adminRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/", {
               headers: { "x-auth-token": token },
            });
            setAdminData({
               token,
               admin: adminRes.data,
            });
         }
      };

      checkLoggedIn();
   }, []);

   const [stripePromise] = useState(() => loadStripe('pk_live_51HwAFyEqZPvW2m9GORLWxJMevHxUSIlU1hwXBN8nyPfrtbFEGpg7FYWIFOmsjBM72il4oPfaO9IlG96xTkiBnIcI00xkq8YxgL'))

   /* GOOGLE ANALYTICS */
   const trackingId = "UA-187921416-1";
   ReactGA.initialize(trackingId);
   ReactGA.pageview(window.location.pathname + window.location.search);


   return (
      <div>
         <UserProvider>
            <AdminContext.Provider value={adminContext}>
               <CartContext.Provider value={cartContext}>
                  <Elements stripe={stripePromise}>
                     <Navbar />
                     <Switch>
                        <Route exact path="/" component={ComboList}></Route>

                        {/*
                     <Route exact path="/clothing" component={ClothesList}></Route>
                     <Route exact path="/posters" component={PostersList}></Route>
                     <Route exact path="/stickers" component={StickersList}></Route>

                     <Route path="/landingalt" component={LandingAlt}></Route>
                     
                     <Route path="/gallery" component={Gallery}></Route>
                     <Route path="/all" component={ArtworksList}></Route>
                     <Route path="/collages" component={CollagesList}></Route>
                     <Route path="/paintings" component={PaintingsList}></Route>
                     */}

                        <Route path="/instagram/sticker/:handle" component={FreeSticker}></Route>

                        <Route path="/information" component={Info}></Route>
                        <Route path="/witchesaresaints" component={WitchesAreSaints}></Route>

                        <Route path="/contact" component={ContactForm}></Route>
                        <Route path="/legal" component={Legal}></Route>

                        <Route path="/cart" component={Cart}></Route>

                        <Route path="/order/redirect/:id" component={OrderRedirect}></Route>
                        <Route path="/order/confirmation/:id" component={OrderConfirmation}></Route>
                        <Route path="/order/error/:id" component={OrderError}></Route>

                        <Route path="/terms-and-conditions" component={TermsAndConditions}></Route>
                        <Route path="/merchandising" component={Merchandising}></Route>
                        <Route path="/shop" component={ShopList}></Route>
                        <Route path="/posters/:identifier" component={PosterDetails}></Route>
                        <Route path="/stickers/:identifier" component={StickerDetails}></Route>
                        <Route path="/clothing/:identifier" component={ClotheDetails}></Route>
                        <Route path="/artworks/:identifier" component={ComboDetails}></Route>

                        <Route path="/admin/login" component={Login}></Route>
                        <PrivateRoute path="/admin/dashboard/global" component={DashboardGlobal}></PrivateRoute>
                        <PrivateRoute path="/admin/dashboard/artworks" component={DashboardArtworks}></PrivateRoute>
                        <PrivateRoute path="/admin/dashboard/design" component={DashboardDesign}></PrivateRoute>
                        <PrivateRoute path="/admin/dashboard/products" component={DashboardProducts}></PrivateRoute>
                        <PrivateRoute path="/admin/dashboard/orders" component={DashboardOrders}></PrivateRoute>

                        <Route component={Default}></Route>
                     </Switch>

                  </Elements>
               </CartContext.Provider>
            </AdminContext.Provider>
         </UserProvider>

      </div>

   );
}

