import React, { useState } from 'react';
import '../../../styles/main.css';
import { Link } from 'react-router-dom';
import PosterBus from './PosterBus'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TRUCKZOOMED from '../../../logos/merch/TRUCKZOOMED.jpg'

export default function Poster(prop) {
   const [artworkOnDisplay, setArtworkOnDisplay] = useState(false);
   const [inheritedWidth, setInheritedWidth] = useState();


   const [truckLoaded, setTruckLoaded] = useState(false);
   const [posterLoaded, setPosterLoaded] = useState(true);

   return (
      <div className="px-4 w-72">

         <div className={prop.spacing} />

         <Link to={"/posters/" + prop.poster.artworkIdentifier}>
            <div className="relative w-full"
               onMouseEnter={() => setArtworkOnDisplay(!artworkOnDisplay)}
               onMouseLeave={() => setArtworkOnDisplay(!artworkOnDisplay)}
               style={{ height: prop.height }}>

               <div className={artworkOnDisplay
                  ? "parent absolute top-0 left-0 w-full flex-col-center"
                  : "opacity-0 parent absolute top-0 left-0 w-full flex-col-center"}
                  style={{ height: prop.height }}
               >
                  <PosterBus poster={prop.poster} busWidth="15" />
               </div>

               <div className={artworkOnDisplay
                  ? "opacity-0 parent absolute top-0 left-0 w-full"
                  : "parent absolute top-0 left-0 w-full"}>

                  <div className="relative flex flex-col items-end" style={{ height: prop.height }}>

                     <LazyLoadImage
                        height={400}
                        src={TRUCKZOOMED} alt="Truck"
                        style={{ height: prop.height }}
                        className={(truckLoaded && posterLoaded)
                           ? "block object-cover w-64"
                           : "opacity-0 block object-cover w-64"}
                        afterLoad={() => setTruckLoaded(true)}
                     />

                     <LazyLoadImage
                        id={"image" + prop.poster._id}
                        src={"https://gasparddeshusses.com/images/artworks/" + prop.poster.artworkIdentifier + ".jpg"}
                        alt={prop.poster.artworkIdentifier}
                        className={(truckLoaded && posterLoaded)
                           ? "absolute m-auto object-contain w-full px-8"
                           : "opacity-0 absolute m-auto object-contain w-full px-8"}
                        style={{ top: "55%", bottom: "50%", maxHeight: "60%" }}
                        afterLoad={() => setPosterLoaded(true)}
                     />


                  </div>
               </div>

            </div>

         </Link>

         <div className="h-3" />

         <div className={(truckLoaded && posterLoaded) ? "block" : "opacity-0"}>
            <h2 className="uppercase font-semibold text-sm leading-tight">{prop.poster.type} – {prop.poster.artworkTitle}</h2>
            <h3 className="text-2xl font-bold leading-tight">{prop.poster.price / 100}€</h3>
         </div>

         <div className={prop.spacing} />

      </div>

   );

}


/*
   render() {
    const { title, img, type, price, inCart } = this.props.product;
    return (
      <div className="m-4">
        <div onClick={() => console.log('you clicked on the image container')}>
          <Link to="/details">
            <img src={img} alt="print" className="object-contain h-64" />
          </Link>

          <h1 className="">{type} - {title}</h1>
          <h2 className="">{price} euros</h2>

          <button className="font-bold text-center border-2 border-black px-4 py-1 focus:outline-none focus:bg-black focus:text-white" disabled={inCart ? true : false} onClick={() => console.log("Added to the cart")}>
            {inCart ? (<p disabled> IN CART</p>) : (<p className="hover:text-white">ADD TO CART</p>)}
          </button>
        </div>

      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id:PropTypes.number,
    img:PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    inCart: PropTypes.bool
  }).isRequired
} */