import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../../styles/main.css';

import Clothe from '../clothes/Clothe';
import Poster from '../posters/Poster';
import Sticker from '../stickers/Sticker';
import ClotheMobile from '../clothes/ClotheMobile'
import PosterMobile from '../posters/PosterMobile'
import StickerMobile from '../stickers/StickerMobile'

// TITLES
import MENU from './../../../logos/admin/MENU.svg'
import STICKERS from '../../../logos/landing/STICKERS.svg'
import POSTERS from '../../../logos/landing/POSTERS.svg'
import CLOTHING from '../../../logos/landing/CLOTHING.svg'


// LAZYLOAD
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export default function ShopList() {
   const [clothesList, setClothesList] = useState([]);
   const [postersList, setPostersList] = useState([]);
   const [stickersList, setStickersList] = useState([]);

   const [sectionLoaded, setSectionLoaded] = useState(false);


   useEffect(() => {

      let isMounted = true;
      const getItems = async () => {
         const getClothesRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/tshirt");
         const getPostersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/poster");
         const getStickersRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/sticker");

         if (isMounted) {
            setClothesList(getClothesRes.data);
            setPostersList(getPostersRes.data);
            setStickersList(getStickersRes.data);
         }
      }

      getItems();
      return () => isMounted = false;
   }, []);

   return (
      <div className="m-0 flex-center z-10">


         {/* ----------- BIG SCREENS VERSION ----------- */}
         {(window.innerWidth > 640) && <div className="w-full">
            <div className={sectionLoaded ? "flex justify-center items-start" : "opacity-0"}>

               <div className="flex-shrink-0 w-48 p-4 bg-white sticky top-0 pt-24">

                  <Link to="/clothing" className="object-fill w-full">
                     <img src={CLOTHING} alt="CLOTHING" className="flex w-full h-10 object-fill hover:h-18 hover:opacity-10" />
                  </Link>

                  <div className="h-1" />

                  <Link to="/posters" className="object-fill w-full">
                     <img src={POSTERS} alt="STICKERS" className="flex w-full h-10 object-fill hover:h-18 hover:opacity-10" />
                  </Link>

                  <div className="h-1" />

                  <Link to="/stickers" className="object-fill w-full">
                     <img src={STICKERS} alt="STICKERS" className="flex w-full h-10 object-fill hover:h-18 hover:opacity-10" />
                  </Link>

               </div>

               <section className="pt-12" onLoad={() => setSectionLoaded(true)}>

                  

                  <div className="flex justify-between items-start">
                     <div className="w-5/12">
                        {clothesList.slice(0,1).map((item) =>
                           <Clothe key={item._id} clothe={item} imagesClass="object-contain w-104 mb-4" globalClass="pl-8" />
                        )}
                     </div>
                     <div className="w-7/12 flex flex-wrap justify-between pl-12 pr-20">
                        {postersList.slice(0, 4).map((item, index) =>
                           <Poster key={item._id}
                              poster={item} busWidth={15} height="11rem" spacing="h-3" />
                        )}
                     </div>


                  </div>

                  <div className="flex flex-wrap w-full items-start justify-between pr-20">
                     {postersList.slice(5).map((item, index) =>
                        <Poster key={item._id}
                           poster={item} busWidth={15} height="16rem" spacing="h-4" />
                     )}
                     {stickersList.slice(0).map((item, index) =>
                        <Sticker key={"stickerElementBig" + item._id}
                           sticker={item} skateWidth={15} />
                     )}
                  </div>

               </section>

            </div>
         </div>}


         {/* ----------- SMALL SCREENS VERSION ----------- */}
         {(window.innerWidth <= 640) && <div className="w-full">

            <div className="h-32" />

            <section className="flex flex-wrap w-full px-2">
               {clothesList.slice(0, 1).map(item =>
                  <div key={item._id} className="w-full">
                     <div className="h-10" />
                     <ClotheMobile clothe={item} />
                  </div>
               )}
            </section>

            <div className="h-8" />

            <section className="flex flex-wrap w-full px-2">
               {postersList.slice(0).map(item =>
                  <PosterMobile key={item._id} poster={item} />
               )}
            </section>

            <div className="h-8" />

            <section className="flex flex-wrap w-full px-2">
               {stickersList.slice(0).map(item =>
                  <StickerMobile key={item._id} sticker={item} />
               )}
            </section>

            <div className="h-40" />

         </div>}

      </div>
   );
}



/*
<div className="flex justify-between items-start">
                     <div className="w-5/12">
                        <div className="h-10" />
                        {clothesList.slice(0, 1).map((item) =>
                           <Clothe key={item._id} clothe={item} imagesClass="object-contain w-104 mb-4" globalClass="pl-8" />
                        )}
                     </div>
                     <div className="w-5/12">
                        <div className="h-10" />
                        {clothesList.slice(1, 2).map((item) =>
                           <Clothe key={item._id} clothe={item} imagesClass="object-contain w-104 mb-4" globalClass="pl-8" />
                        )}
                     </div>
                     <div className="w-1/12 flex-none"></div>
                  </div>
*/