import '../../styles/main.css';
import Ig from '../../logos/ig.png';
import Mail from '../../logos/mail.png';
import { Link } from 'react-router-dom';

export default function Social() {
   return (
      <div className="flex-col-center">
         <div className="flex-center">
            <a href="https://instagram.com/ga5parov" target="_blank" rel="noreferrer" className="md:hover:opacity-0">
               <img id="ig" src={Ig}
                  alt="instagram profile" className="h-10" />
            </a>

            <div className="w-2"></div>

            <Link to={{ pathname: '/contact', state: { subject: "Hello!", origin: "social" } }} className="md:hover:opacity-0">
               <img id="mail" src={Mail} alt="email"
                  className="h-11" />
            </Link>
         </div>

         <Link to="/legal" className="pl-1 pt-1 md:hover:opacity-0 text-xs">LEGAL INFORMATION</Link>
      </div>
   );
}

/*
<Link to="https://www.linkedin.com/in/gasparddeshusses/" className="hover:opacity-0">
            <img id="li" src={Li}
              alt="linkedin profile" className="h-6 mx-1" />
          </Link>
           */
