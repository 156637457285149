import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../../styles/main.css';
import ErrorNotice from '../utils/ErrorNotice';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TRASH from './../../logos/admin/TRASH.svg'
import DeleteConfirmation from "../utils/DeleteConfirmation";
import { SCREEN_HEIGHT } from "../../helpers/constants";

export default function DashboardDesign() {

   const [adminData, setAdminData] = useState();
   const [error, setError] = useState();

   const [title, setTitle] = useState("");
   const [type, setType] = useState("");
   const [date, setDate] = useState("");
   const [location, setLocation] = useState("");
   const [identifier, setIdentifier] = useState("");
   const [src, setSrc] = useState("");
   const [heightCm, setHeightCm] = useState("");
   const [widthCm, setWidthCm] = useState("");
   const [image, setImage] = useState("");
   const [sold, setSold] = useState(false);

   const [fileWidth, setFileWidth] = useState("");

   const [sizesUpdatedSuccess, setSizesUpdatedSuccess] = useState("");

   const [artworksList, setArtworksList] = useState([]);
   const [artworkToSubmit, setArtworkToSubmit] = useState("");
   const [editMode, setEditMode] = useState(false);

   let fileInputElement = document.querySelector("#fileInputElement")

   useEffect(() => {
      const checkLoggedIn = async () => {
         let token = localStorage.getItem("x-auth-token");
         if (token === null) {
            localStorage.setItem("x-auth-token", "");
            token = "";
         }
         const tokenValidRes = await axios.post(
            "https://gasparddeshusses.herokuapp.com/api/admin/tokenIsValid", null, { headers: { "x-auth-token": token } }
         );
         if (tokenValidRes.data) {
            const adminRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/", {
               headers: { "x-auth-token": token },
            });
            setAdminData({
               token,
               admin: adminRes.data,
            });

            const getArtworksRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/artworks/", {
               headers: { "x-auth-token": token },
            });

            setArtworksList(getArtworksRes.data);
         }
      };


      checkLoggedIn();
   }, []);

   const addArtwork = async (e) => {
      e.preventDefault();
      try {
         const formData = new FormData();
         formData.append('title', title)
         formData.append('type', type)
         formData.append('date', date.toUTCString())
         formData.append('location', location)
         formData.append('identifier', identifier)
         formData.append('src', "src")
         formData.append('heightCm', heightCm)
         formData.append('widthCm', widthCm)
         formData.append('sold', sold)
         formData.append('image', fileInputElement.files[0])
         await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/artworks/add", formData, {
            headers: { "x-auth-token": adminData.token },
         });
         generateProducts();
         setTitle(""); setType(""); setDate(""); setLocation(""); setIdentifier(""); setHeightCm(""); setWidthCm(""); setImage([]);
         document.querySelector("#submitButton").blur();
      } catch (error) {
         setError(error.response.data.error);
      }
   };

   const generateProducts = async () => {
      try {
         const original = {
            artworkTitle: title,
            artworkDate: date,
            artworkType: type,
            artworkLocation: location,
            artworkIdentifier: identifier,
            artworkWidthCm: widthCm,
            artworkHeightCm: heightCm,
            type: "original",
            price: 10000,
            forSale: !sold,
            inStock: 1,
            src: "https://gasparddeshusses-artworks.s3.eu-west-3.amazonaws.com/artworks/" + identifier + ".jpg"
         };
         const sticker = {
            artworkTitle: title,
            artworkDate: date,
            artworkType: type,
            artworkLocation: location,
            artworkIdentifier: identifier,
            artworkWidthCm: widthCm,
            artworkHeightCm: heightCm,
            type: "sticker",
            price: 500,
            forSale: true,
            inStock: 100,
            src: "https://gasparddeshusses-artworks.s3.eu-west-3.amazonaws.com/artworks/" + identifier + ".jpg"
         };
         const poster = {
            artworkTitle: title,
            artworkDate: date,
            artworkType: type,
            artworkLocation: location,
            artworkIdentifier: identifier,
            artworkWidthCm: widthCm,
            artworkHeightCm: heightCm,
            type: "poster",
            price: 3900,
            forSale: true,
            inStock: 100,
            src: "https://gasparddeshusses-artworks.s3.eu-west-3.amazonaws.com/artworks/" + identifier + ".jpg"
         };

         await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/products/add", sticker, {
            headers: { "x-auth-token": adminData.token },
         });
         await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/products/add", poster, {
            headers: { "x-auth-token": adminData.token },
         });
         await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/products/add", original, {
            headers: { "x-auth-token": adminData.token },
         });

      } catch (error) {
         setError(error.response.data.error);
      }
   }

   const clearArtwork = (e) => {
      e.preventDefault();
      setTitle("");
      setType("");
      setDate("");
      setLocation("");
      setIdentifier("");
      setHeightCm("");
      setWidthCm("");
      setImage([]);
      setSrc("");
      setFileWidth("");
      setEditMode(false)
   }

   const deleteArtwork = async (id) => {
      try {
         await axios.delete('https://gasparddeshusses.herokuapp.com/api/admin/artworks/delete/' + id, {
            headers: { "x-auth-token": adminData.token },
         });
         setDeleteArtworkMode(false);
      } catch (error) {
         console.log(error);
      }
   }

   const getArtworkToModify = async (id) => {
      try {
         const artworkFound = await axios.get('https://gasparddeshusses.herokuapp.com/api/admin/artworks/' + id);
         setTitle(artworkFound.data.title);
         setType(artworkFound.data.type);
         setDate(new Date(artworkFound.data.date));
         setLocation(artworkFound.data.location);
         setIdentifier(artworkFound.data.identifier);
         setSrc(artworkFound.data.src)
         setHeightCm(artworkFound.data.heightCm);
         setWidthCm(artworkFound.data.widthCm);
         setArtworkToSubmit(artworkFound.data._id)
         setSold(artworkFound.data.sold)
         setFileWidth(document.getElementById("preview").naturalWidth);
         setEditMode(true);
      } catch (error) {
         console.log(error);
      }
   }

   const modifyArtwork = async (e) => {
      e.preventDefault();
      try {
         console.log(date);
         let id = artworkToSubmit;
         const artworkToEdit = { title, type, date, location, identifier, heightCm, widthCm, sold, src };
         await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/artworks/update/' + id, artworkToEdit, {
            headers: { "x-auth-token": adminData.token },
         });
         setTitle("");
         setType("");
         setDate("");
         setLocation("");
         setIdentifier("");
         setSrc("");
         setSold(false);
         setHeightCm("");
         setWidthCm("");
         setEditMode(false);
         document.querySelector("#submitButton").blur();
      } catch (error) {
         console.log(error);
      }
   }

   const toggleSale = async (id, isSold) => {
      try {
         let changeSold = { sold: !isSold }
         await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/artworks/update/' + id, changeSold, {
            headers: { "x-auth-token": adminData.token },
         });

      } catch (error) {
         console.log(error);
      }
   }

   const [deleteArtworkMode, setDeleteArtworkMode] = useState(false);
   const [orderIdToDelete, setArtworkIdToDelete] = useState("");

   const getArtworkToDelete = id => {
      setDeleteArtworkMode(true)
      setArtworkIdToDelete(id)
   }

   const updateImgSize = async (item) => {
      try {
         const artworkToEdit = { actualWidth: document.getElementById("img" + item._id).naturalWidth, actualHeight: document.getElementById("img" + item._id).naturalHeight };

         const test = await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/artworks/update/' + item._id, artworkToEdit, {
            headers: { "x-auth-token": adminData.token },
         });
         console.log(test)
         setSizesUpdatedSuccess("ok");
      } catch (error) {
         console.log(error);
      }
   }

   const addNewTypeOfProduct = async (item) => {
      try {
         const original = {
            artworkTitle: item.title,
            artworkDate: item.date,
            artworkType: item.type,
            artworkLocation: item.location,
            artworkIdentifier: item.identifier,
            artworkWidthCm: item.widthCm,
            artworkHeightCm: item.heightCm,
            type: "original",
            price: 50000,
            forSale: false,
            inStock: 1,
            src: "https://gasparddeshusses-artworks.s3.eu-west-3.amazonaws.com/artworks/" + identifier + ".jpg"
         };

         const res = await axios.post("https://gasparddeshusses.herokuapp.com/api/admin/products/add", original, {
            headers: { "x-auth-token": adminData.token },
         });
         console.log(res);
      } catch (error) {
         console.log(error);
      }
   }

   return (
      <>

         {deleteArtworkMode && <DeleteConfirmation delete={() => deleteArtwork(orderIdToDelete)} goBack={() => setDeleteArtworkMode(false)} type="ARTWORK" />}


         <div className="w-full flex-col-center">
            <div className="hidden md:block mb-12" style={{ height: 0.12 * SCREEN_HEIGHT }}></div>

            {error && (
               <ErrorNotice message={error} clearError={() => setError(undefined)} />
            )}
            <div className="w-full flex flex-col-reverse md:flex-row justify-center items-start pt-24 md:pt-0 md:px-20">

               <div className="hidden md:block h-full pr-8">

                  <div className="h-6 w-full" />

                  <img id="preview" src={src} alt={identifier} className={editMode ? "w-20" : "w-20 opacity-0"} />

                  {fileWidth && <div>
                     <p className="text-xs text-center py-1">{fileWidth} x 1000</p>
                     {(widthCm === 1) && <p className="bg-yellow-200 rounded text-xs text-center">size missing</p>}
                  </div>}


               </div>

               <form id="myForm" onSubmit={editMode ? modifyArtwork : addArtwork} className="w-full md:w-96 flex flex-col justify-center items-center md:items-start text-xl text-center">

                  <div className="h-6 w-full" />

                  <input required onChange={(e) => setTitle(e.target.value)} className="focus w-80 pl-2" placeholder="Title" value={title}></input>

                  <div className="h-2"></div>

                  <input required onChange={(e) => setType(e.target.value)} className="focus w-80 pl-2" placeholder="Type" value={type}></input>

                  <div className="h-2"></div>

                  <DatePicker className="w-80 pl-2 focus " dateFormat="dd/MM/yyyy" selected={date} onChange={date => setDate(date)} placeholderText="Date" required></DatePicker>

                  <div className="h-2"></div>

                  <input required onChange={(e) => setLocation(e.target.value)} className="focus w-80 pl-2" placeholder="Made in..." value={location}></input>

                  <div className="h-2"></div>

                  <input required onChange={(e) => setIdentifier(e.target.value)} className="focus w-80 pl-2" placeholder="Identifier" value={identifier}></input>

                  <div className="h-2"></div>

                  <div className="relative w-80 flex flex-row justify-between">

                     <div className="flex flex-row justify-between items-center">
                        <input required onChange={(e) => setWidthCm(e.target.value)} className={
                           ((fileWidth < 1000 && widthCm > heightCm) || (fileWidth > 1000 && widthCm < heightCm))
                              ? "w-10 justify-self-strecth border-2 border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 text-center "
                              : "w-10 justify-self-strecth focus text-center"} placeholder="W" value={widthCm}></input>
                        <div className="px-1">x</div>
                        <input required onChange={(e) => setHeightCm(e.target.value)} className={
                           ((fileWidth < 1000 && widthCm > heightCm) || (fileWidth > 1000 && widthCm < heightCm))
                              ? "w-10 justify-self-strecth border-2 border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 text-center "
                              : "w-10 justify-self-strecth focus text-center"}
                           placeholder="H" value={heightCm}></input>
                     </div>

                     <div className="w-4" />

                     {sold
                        ? <div onClick={() => setSold(!sold)} className="w-60 flex-center text-sm font-medium btn-black bg-green-500">ELLE EST VENDUE</div>
                        : <div onClick={() => setSold(!sold)} className="w-60 flex-center text-sm font-medium btn-black bg-red-500">ELLE N'EST PAS VENDUE</div>}
                  </div>



                  <div className="h-2"></div>

                  {editMode
                     ? <input required onChange={(e) => setSrc(e.target.value)} className="focus w-80 pl-2" placeholder="Src" value={src}></input>
                     : <div className="relative">
                        <input id="fileInputElement" type="file" className="w-80 flex justify-between bg-white focus:outline-none text-lg bg-gradient-to-r from-white z-20 border-transparent" required />

                        <div className="absolute top-0 w-36 ring-8 ring-white h-8 flex-center text-sm font-medium btn-black"
                           onClick={() => fileInputElement.click()}>CHOOSE FILE</div>
                     </div>}


                  <div className="h-8"></div>

                  <div className="flex-col-center w-80">
                     <input id="submitButton" type="submit" value={(!editMode) ? "ADD A PROJECT" : "EDIT " + title} className="text-xl font-bold w-64 py-2 btn-black text-center px-6" style={{ maxWidth: "20rem" }}></input>

                     {(!editMode) ? <div></div> : <button onClick={(e) => clearArtwork(e)}
                        className="underline text-sm pt-2 hover:opacity-0">Go back to "add a new project" mode</button>}
                  </div>



               </form>


               <div className="w-full md:w-1/2 flex flex-col">
                  <div className="h-6 w-full pb-2 text-center">LIST OF PROJECTS</div>

                  <div className="h-56 md:h-96 pt-2 pb-20 px-4 overflow-y-auto border border-black">
                     {artworksList.map(item =>
                        <div className="w-full grid grid-cols-12" key={item._id}>
                           <img id={"img" + item._id} src={item.src}
                              alt={item.identifier} className="col-span-1 h-5 object-cover w-full" />
                           <p className="pl-2 col-span-8 md:col-span-6 font-medium truncate">{item.title}</p>


                           <p className={(item.widthCm === 1)
                              ? "hidden md:block col-span-1 text-xs flex-center bg-yellow-200 px-2 my-1 rounded"
                              : "hidden md:block col-span-1 text-xs flex-center bg-blue-200 px-2 my-1 rounded"}>{item.widthCm}x{item.heightCm}</p>
                           <div className="hidden md:block md:col-span-2 flex-center">
                              <div className="text-xs flex-center bg-green-200 px-2 my-1 rounded w-20">{(new Date(item.date).toLocaleDateString())}</div>
                           </div>

                           <div className="col-span-3 md:col-span-2 w-full flex flex-row justify-end md:justify-between md:pr-2">
                              <button className="hover:opacity-0 focus:outline-none" onClick={() => { getArtworkToModify(item.identifier) }}>edit</button>
                              <div className="h-5 w-5 mx-1 hover:opacity-0" onClick={() => getArtworkToDelete(item._id)}>
                                 <img className="object-contain h-5 w-5" src={TRASH} alt="D"></img>
                              </div>
                              <input type="checkbox" className="form-checkbox h-5 w-5 text-green-600" onChange={() => toggleSale(item._id, item.sold)} checked={item.sold}></input>
                           </div>
                        </div>)}
                  </div>
               </div>

            </div>
         </div>
      </>

   );
}
