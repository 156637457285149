import React, { useState, useEffect, useContext } from "react";
import AdminContext from "../../context/AdminContext";

import '../../styles/main.css';
import Logo from '../../logos/monogramme.svg'
import AuthOptions from '../utils/AuthOptions'
import CartContext from "../../context/CartContext";

import NavbarMenu from "../utils/NavbarMenu";
import { SMALL_DEVICE } from "../../helpers/constants";


export default function Navbar() {
   const { adminData, setAdminData } = useContext(AdminContext);
   const { numberItemsCart, setNumberItemsCart } = useContext(CartContext);
   const [location, setLocation] = useState("/");

   // ----------- ITEMS IN CART ----------- //
   const [items, setItems] = useState([]);

   useEffect(() => {
      const items = JSON.parse(localStorage.getItem('items'));
      if (items) {
         setItems(items);
         setNumberItemsCart(items.length);
      }

   }, [setNumberItemsCart]);

   const [menuOpen, setMenuOpen] = useState(false);

   function openMenuMobile() {
      if (menuOpen) {
         document.querySelector("#monogramme").style.transform = "rotate(0deg)";
         document.querySelector("body").style.overflow = "auto"
         setMenuOpen(!menuOpen)
      } else {
         document.querySelector("#monogramme").style.transform = "rotate(360deg)";
         document.querySelector("body").style.overflow = "hidden"
         setMenuOpen(!menuOpen)
      }

   }


   // Get div center on the actual mobile browser viewport height
   const [mobileScreenHeight, setMobileScreenHeight] = useState(700)

   useEffect(() => {
      setMobileScreenHeight(window.innerHeight)
   }, []);


   if (SMALL_DEVICE) {
      return <div className="z-50" onLoad={() => setLocation(window.location.pathname.replace("/", ""))}>

         <div className={`menu  ${menuOpen ? 'show' : ''}`}>
            <NavbarMenu
               onClose={() => {
                  setMenuOpen(false)
                  document.querySelector("html").style.overflow = "auto"
               }}
               location={location}
            />
         </div>

         <div className="z-50 w-full fixed inline-flex justify-between items-center bg-white" style={{ height: 0.12 * mobileScreenHeight }}>

            <div className="w-12 ml-4">

            </div>

            <div className="z-40 focus:outline-none no-gray-square" onClick={() => openMenuMobile()}>
               <img id="monogramme" className="z-100 h-10 w-10 focus:outline-none object-fill" src={Logo} alt="monogramme" />
            </div>

            {adminData.admin && <AuthOptions />}

            <div className="w-12 mr-4">
               {(numberItemsCart > 0) &&
                  <a href="/cart" className="flex flex-row font-bold focus:outline-none">

                     <div className="relative flex h-8 w-8 mr-8 focus:outline-none md:hover:opacity-0">
                        <p className="absolute top-0 w-8 h-8 text-4xl flex-center">
                           {numberItemsCart}
                        </p>
                     </div>

                  </a>}
            </div>
         </div>
      </div>
   }

   return (
      <div onLoad={() => setLocation(window.location.pathname.replace("/", ""))}>

         {menuOpen
            ? <div className="z-40 fixed top-0 w-full px-6 md:pl-40 md:pr-18 h-screen bg-white">
               <NavbarMenu
                  onClose={() => setMenuOpen(false)}
                  location={location}
               />
            </div>
            : null
         }

         <div className="z-50 w-48 h-screen fixed inline-flex justify-center items-center bg-white">
            <div className="z-50 focus:outline-none no-gray-square" onClick={() => setMenuOpen(!menuOpen)}>
               <img className="z-100 h-16 w-16 focus:outline-none hover:h-32 object-fill" src={Logo} alt="monogramme" />
            </div>
         </div>

         {adminData.admin
            ? <div className="w-full fixed inline-flex justify-between items-center z-30" style={{ height: "13vh" }}>
               <AuthOptions />
            </div>
            : null
         }

         {numberItemsCart > 0
            ? <a href="/cart" className="fixed right-0 inline-flex justify-between items-center z-30" style={{ height: "13vh" }}>

               <div className="relative flex h-8 w-8 mr-8 focus:outline-none md:hover:opacity-0">
                  <p className="absolute top-0 w-8 h-8 text-4xl flex-center font-bold">
                     {numberItemsCart}
                  </p>
               </div>

            </a>
            : null
         }

      </div>);
}

