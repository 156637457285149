import React, { useState, useEffect } from 'react';
import '../../styles/main.css';


export default function DeleteConfirmation(prop) {


   return (
      <>
         <div className="flex-col-center w-screen h-screen">
            <button className="text-xl font-bold w-64 py-2 btn-red" onClick={prop.delete}>DELETE {prop.type}</button>

            <div className="h-2" />

            <button className="text-xl font-bold w-64 py-2 btn-black" onClick={prop.goBack}>GO BACK</button>
         </div>

      </>
   );

}


