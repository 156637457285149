import React, { useState } from 'react';
import '../../../styles/main.css';
import { Link } from 'react-router-dom';
import StickerSkateboard from './StickerSkateboard'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SKATEZOOMED from '../../../logos/merch/SKATEZOOMED.jpg'

export default function Poster(prop) {
   const [artworkOnDisplay, setArtworkOnDisplay] = useState(false);
   const [inheritedWidth, setInheritedWidth] = useState();


   const [skateLoaded, setSkateLoaded] = useState(false);
   const [stickerLoaded, setStickerLoaded] = useState(true);

   return (
      <div className="px-4 w-72">

         <div className="h-8" />

         <Link to={"/stickers/" + prop.sticker.artworkIdentifier}>
            <div className="relative h-64 w-full"
               onMouseEnter={() => setArtworkOnDisplay(true)}
               onMouseLeave={() => setArtworkOnDisplay(false)}>

               <div id={"zoomed_out" + prop.sticker._id}
                  className={artworkOnDisplay
                     ? "parent absolute top-0 left-0 h-64 w-full flex-col-center"
                     : "opacity-0 parent absolute top-0 left-0 h-64 w-full flex-col-center"}
               >
                  <StickerSkateboard sticker={prop.sticker} skateWidth="4" />
               </div>

               <div id={"infocontainer" + prop.sticker._id}
                  className={artworkOnDisplay
                     ? "opacity-0 parent absolute top-0 left-0 h-64 w-full"
                     : "parent absolute top-0 left-0 h-64 w-full"}>

                  <div className="relative flex flex-col items-end h-64">

                     <LazyLoadImage
                        height={400}
                        src={SKATEZOOMED} alt="Truck"
                        style={{ width: 400 + "rem" }}
                        className={(skateLoaded && stickerLoaded)
                           ? "block object-cover h-64 w-48"
                           : "opacity-0 block object-cover h-64 w-48"}
                        afterLoad={() => setSkateLoaded(true)}
                     />

                     <LazyLoadImage
                        id={"image" + prop.sticker._id}
                        src={"https://gasparddeshusses.com/images/artworks/" + prop.sticker.artworkIdentifier + ".jpg"}
                        alt={prop.sticker.artworkIdentifier}
                        className={(skateLoaded && stickerLoaded)
                           ? "absolute m-auto object-contain w-full px-8"
                           : "opacity-0 absolute m-auto object-contain w-full px-8"}
                        style={{ top: "55%", bottom: "50%", maxHeight: "60%" }}
                        afterLoad={() => setStickerLoaded(true)}
                     />


                  </div>
               </div>

            </div>

         </Link>

         <div className="h-1" />

         <div className={(skateLoaded && stickerLoaded) ? "block" : "opacity-0"}>
            <h2 className="uppercase font-semibold text-sm">{prop.sticker.type} – {prop.sticker.artworkTitle}</h2>
            <h3 className="text-2xl font-bold">{prop.sticker.price / 100}€</h3>
         </div>

         <div className="h-8" />

      </div>

   );

}


/*
   render() {
    const { title, img, type, price, inCart } = this.props.product;
    return (
      <div className="m-4">
        <div onClick={() => console.log('you clicked on the image container')}>
          <Link to="/details">
            <img src={img} alt="print" className="object-contain h-64" />
          </Link>

          <h1 className="">{type} - {title}</h1>
          <h2 className="">{price} euros</h2>

          <button className="font-bold text-center border-2 border-black px-4 py-1 focus:outline-none focus:bg-black focus:text-white" disabled={inCart ? true : false} onClick={() => console.log("Added to the cart")}>
            {inCart ? (<p disabled> IN CART</p>) : (<p className="hover:text-white">ADD TO CART</p>)}
          </button>
        </div>

      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id:PropTypes.number,
    img:PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    inCart: PropTypes.bool
  }).isRequired
} */