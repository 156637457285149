import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../../styles/main.css';
import ErrorNotice from '../utils/ErrorNotice';
import TRASH from './../../logos/admin/TRASH.svg'
import { SCREEN_HEIGHT } from "../../helpers/constants";

export default function DashboardProducts() {

   const [adminData, setAdminData] = useState();
   const [error, setError] = useState();

   const [artworkTitle, setArtworkTitle] = useState("");
   const [artworkType, setArtworkType] = useState("");
   const [artworkDate, setArtworkDate] = useState("");
   const [artworkLocation, setArtworkLocation] = useState("");
   const [artworkIdentifier, setArtworkIdentifier] = useState("");
   const [artworkHeightCm, setArtworkHeightCm] = useState("");
   const [artworkWidthCm, setArtworkWidthCm] = useState("");

   const [type, setType] = useState("");
   const [price, setPrice] = useState("");
   const [forSale, setForSale] = useState(false);
   const [inStock, setInStock] = useState("");


   const [productsList, setProductsList] = useState([]);
   const [productToSubmit, setProductToSubmit] = useState("");
   const history = useHistory();

   useEffect(() => {
      let isMounted = true;
      const checkLoggedIn = async () => {
         let token = localStorage.getItem("x-auth-token");
         if (token === null) {
            localStorage.setItem("x-auth-token", "");
            token = "";
         }
         const tokenValidRes = await axios.post(
            "https://gasparddeshusses.herokuapp.com/api/admin/tokenIsValid", null, { headers: { "x-auth-token": token } }
         );
         if (tokenValidRes.data) {
            const adminRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/", {
               headers: { "x-auth-token": token },
            });
            setAdminData({
               token,
               admin: adminRes.data,
            });

            const getProductsRes = await axios.get("https://gasparddeshusses.herokuapp.com/api/admin/products/", {
               headers: { "x-auth-token": token },
            });
            setProductsList(getProductsRes.data);
         }
      };

      checkLoggedIn();
      return () => isMounted = false;
   }, []);




   const deleteProduct = async (id) => {
      try {
         await axios.delete('https://gasparddeshusses.herokuapp.com/api/admin/products/delete/' + id, {
            headers: { "x-auth-token": adminData.token },
         });
         history.go(0)
      } catch (error) {
         console.log(error);
      }
   }

   const toggleSale = async (id, isForSale) => {
      try {
         let changeForSale = { forSale: !isForSale }
         await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/products/update/' + id, changeForSale, {
            headers: { "x-auth-token": adminData.token },
         });

      } catch (error) {
         console.log(error);
      }
   }

   const getProductToModify = async (id) => {
      try {
         const productFound = await axios.get('https://gasparddeshusses.herokuapp.com/api/admin/products/id/' + id);
         setArtworkTitle(productFound.data.artworkTitle);
         setArtworkDate(new Date(productFound.data.artworkDate));
         setArtworkType(productFound.data.artworkType);
         setArtworkLocation(productFound.data.artworkLocation);
         setArtworkIdentifier(productFound.data.artworkIdentifier);
         setArtworkWidthCm(productFound.data.artworkWidthCm);
         setArtworkHeightCm(productFound.data.artworkHeightCm);

         setType(productFound.data.type);
         setPrice(productFound.data.price / 100);
         setForSale(productFound.data.forSale);
         setInStock(productFound.data.inStock);


         setProductToSubmit(productFound.data._id);
      } catch (error) {
         console.log(error);
      }
   }

   const modifyProduct = async (e) => {
      e.preventDefault();
      try {
         console.log(artworkDate);
         let id = productToSubmit;
         const productToEdit = { artworkTitle, artworkDate, artworkType, artworkLocation, artworkIdentifier, artworkWidthCm, artworkHeightCm, type, price: price * 100, forSale, inStock };
         await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/products/update/' + id, productToEdit, {
            headers: { "x-auth-token": adminData.token },
         });
         setArtworkTitle("");
         setArtworkDate("");
         setArtworkType("");
         setArtworkLocation("");
         setArtworkIdentifier("");
         setArtworkWidthCm("");
         setArtworkHeightCm("");
         setType("");
         setPrice("");
         setForSale("");
         setInStock("");
         document.querySelector("#submitButton").blur();
      } catch (error) {
         console.log(error);
      }
   }



   // MODIFY PRODUCTS BY BATCH
   const launchBatch = async () => {
      productsList.forEach(item => {
         if (item.type === "original") {
            modifyBatchProduct(item);
         }
      })

   }

   const modifyBatchProduct = async (item) => {
      try {
         const productToEdit = { src: "https://gasparddeshusses-artworks.s3.eu-west-3.amazonaws.com/artworks/" + item.artworkIdentifier + ".jpg" };
         await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/products/update/' + item._id, productToEdit, {
            headers: { "x-auth-token": adminData.token },
         });
      } catch (error) {
         console.log(error);
      }
   }


   return (
      <div>

         <div className="w-full flex-col-center">
            <div className="hidden md:block mb-12" style={{ height: 0.12 * SCREEN_HEIGHT }}></div>

            {/* <div onClick={launchBatch}>BATCH MODIFY</div> */}

            {error && (
               <ErrorNotice message={error} clearError={() => setError(undefined)} />
            )}
            <div className="w-full flex flex-col-reverse md:flex-row justify-center items-start md:px-20 pt-24 md:pt-0">

               <form id="myForm" onSubmit={modifyProduct} className="w-full md:w-96 flex flex-col justify-center items-center md:items-start text-xl text-center">
                  <div className="h-6 w-full"></div>

                  <div className="flex flex-row justify-start items-center w-80">
                     <label className="px-2 text-base">Produit en vente</label>
                     <input type="checkbox" onChange={() => setForSale(!forSale)} className="form-checkbox h-5 w-5" checked={forSale}></input>
                  </div>



                  <div className="h-2"></div>

                  <input required onChange={(e) => setArtworkTitle(e.target.value)} className="focus w-80 pl-2" placeholder="Titre du produit" value={artworkTitle}></input>

                  <div className="h-2"></div>

                  <input required onChange={(e) => setType(e.target.value)} className="focus w-80 pl-2" placeholder="Type de produit" value={type}></input>

                  <div className="h-2"></div>

                  <div className="flex flex-row justify-start items-center w-80">
                     <label className="px-2 text-base">Price on demand</label>
                     <input type="checkbox" onChange={() => setPrice(price === 0 ? 1 : 0)} className="form-checkbox h-5 w-5" checked={price === 0}></input>
                  </div>

                  <div className="h-2"></div>

                  {price === 0
                     ? null
                     : <input required onChange={(e) => setPrice(e.target.value)} className="focus w-80 pl-2" placeholder="Prix" value={price}></input>}



                  <div className="h-8"></div>

                  <div className="flex-col-center w-80">
                     <input id="submitButton" type="submit" value={"EDIT " + artworkTitle} className="text-xl font-bold w-64 py-2 btn-black px-6" style={{ maxWidth: "20rem" }}></input>
                  </div>


               </form>


               <div className="w-full md:w-1/2 flex flex-col">
                  <div className="h-6 w-full pb-2 text-center">LIST OF PRODUCTS</div>

                  <div className="h-80 md:h-96 pt-2 pb-20 px-4 overflow-y-auto border border-black">
                     {productsList.map(item =>
                        <div className="w-full grid grid-cols-12" key={item._id}>
                           <img id={"img" + item._id} src={item.src}
                              alt={item.artworkIdentifier} className="col-span-1 h-5 object-cover w-full" />
                           <p className="pl-2 col-span-5 font-medium truncate">{item.artworkTitle}</p>
                           <p className="col-span-2 md:col-span-1 text-xs flex-center bg-blue-200 px-2 my-1 rounded">{item.artworkWidthCm}x{item.artworkHeightCm}</p>
                           <div className="hidden md:block col-span-2 flex-center">

                              {(item.type === "poster") && <p className="text-xs flex-center bg-green-200 px-2 my-1 rounded w-12">{item.type}</p>}
                              {(item.type === "sticker") && <p className="text-xs flex-center bg-purple-200 px-2 my-1 rounded w-12">{item.type}</p>}
                              {(item.type === "original") && <p className="text-xs flex-center bg-red-200 px-2 my-1 rounded w-12">{item.type}</p>}
                              {(item.type === "tshirt") && <p className="text-xs flex-center bg-pink-200 px-2 my-1 rounded w-12">{item.type}</p>}


                           </div>
                           <p className="col-span-2 md:col-span-1 w-full text-center">{item.price / 100}€</p>

                           <div className="col-span-2 text-right flex-center">
                              <button className="hover:opacity-0 focus:outline-none" onClick={() => { getProductToModify(item._id) }}>edit</button>

                              <div className="w-2" />

                              <input type="checkbox" className="form-checkbox h-5 w-5 text-green-600" onChange={() => toggleSale(item._id, item.forSale)} checked={item.forSale}></input>

                              <div className="w-2" />

                              <button className="hover:opacity-0 focus:outline-none" onClick={() => deleteProduct(item._id)}>
                                 <img className="object-contain h-5 w-5" src={TRASH} alt="D"></img>
                              </button>
                           </div>
                        </div>)}
                  </div>
               </div>

            </div>
         </div>
      </div >

   );
}


/*
const updateSrc = async (item) => {
         try {
         const productToEdit = { src: "https://gasparddeshusses-artworks.s3.eu-west-3.amazonaws.com/artworks/" + item.artworkIdentifier + ".jpg" };
         await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/products/update/' + item._id, productToEdit, {
            headers: { "x-auth-token": adminData.token },
         });
      } catch (error) {
         console.log(error);
      }}

      checkLoggedIn();

      productsList.map(item =>
      updateSrc(item));



      onClick={() =>  productsList.map(item => updatePrice(item))}


      const updatePrice = async (item) => {
      try {
      const priceToEdit = { price: 3900 };
      await axios.patch('https://gasparddeshusses.herokuapp.com/api/admin/products/update/' + item._id, priceToEdit, {
         headers: { "x-auth-token": adminData.token },
      });
   } catch (error) {
      console.log(error);
   }}

      */