import React from "react";
import '../../styles/main.css';
import Social from './Social';

export default function Info() {

   return (
      <>

         <div className="w-full hidden md:block">

            <div className="w-1/2 flex flex-col items-end z-20" style={{ fontWeight: 450 }}>

               <div className="h-24" />

               <p className="flex-none text-lg text-justify leading-6 md:w-104 mr-8">Born in March 1991, Gaspard Deshusses is an independent artist, painter and graphic designer native of the French Alps. Fascinated with colored shapes, he disassembles, associates and searches for a balance between abstract and figurative appearances, as well as their very limits.<br /><br />In 2015, while he was still a graphic design student, he started frantically tearing up all the fashion magazines he could find, and turned them into abstract and colored collage tableaux. He created dozens of works using this process throughout the following years. Gaspard obtained a Masters of Applied Arts in 2016, graduating with honors, and learned to differentiate art and design over time. The pursuit of his research endeavor then brought him to watercolor and oil paint. This changeover was a way for him to enlarge his formats, work on his own colors, and make his graphic approach evolve while including a stronger figurative dimension within his work.<br /><br />Buoyed by religious and fashion iconography as much as prehistoric art, Gaspard aims to create wild and sensual moods through methodical and structured compositions. He works by associating shapes and colors through textures, shades, layers, strokes and swathes to create a scaffold of edged representations. This orderly chaos is his chosen way of illustrating a paradox between the contrast that comes with confrontation and the harmony which eventually arises from any kind of coexistence.<br /><br />After living several years in Paris, New York City and Brussels, Gaspard now lives and works in the French Alps. Based at the heart of the mountains where his studio is located, he takes a carnal and spiritual look at his real and digital environment. Through a profuse production, his work assumes a raw and experimental approach, driven by an obsessive research endeavor. </p>

               <div className="h-24" />

            </div>

            <div className="w-1/2 fixed h-screen top-0 right-0 flex justify-start items-center" style={{ fontWeight: 450 }}>
               <div className="w-96 ml-8">

                  <div className="h-24" />

                  <h2 className="text-xl font-bold text-center">CONTRIBUTION</h2>
                  <div className="h-1" />
                  <ul className="w-full">
                     <label className="block leading-5">October / November 2021 — Paris, France</label>
                     <li className="leading-5"><a href="https://fb.me/e/33tgdFNbV" target="_blank" rel="noreferrer" className="hover:opacity-0"><span className="fraktur">Witches Are Saints</span> solo show @ l’Autre Thé tea shop</a></li>

                     <div className="h-2"></div>

                     <label className="block leading-5">July 2021 – Paris, France</label>
                     <li className="leading-5"><a href="https://www.facebook.com/events/774543956503476/" target="_blank" rel="noreferrer" className="hover:opacity-0">Floréal Belleville SUPERPAUSE ( ϟ ll ) group show</a></li>

                     <div className="h-2"></div>

                     <label className="block leading-5">March 2021 – Paris, France</label>
                     <li className="leading-5"><a href="https://www.cocktail-brut.com/interviews/2021/2/24/46-gaspard-deshusses" target="_blank" rel="noreferrer" className="hover:opacity-0">Interview for <span class="hidden md:inline-block">the fashion boutique</span> Cocktail Brut</a></li>

                     <div className="h-2"></div>

                     <label className="block leading-5">April 2020 – Los Angeles, USA</label>
                     <li className="leading-5"><a href="https://wwd.com/fashion-news/fashion-features/gallery/safe-harbor-artist-series-day-one-1203625633/wwd-artists-respond-to-the-meaning-of-home-amidst-coronavirus-lockdowns-3/" target="_blank" rel="noreferrer" className="hover:opacity-0">Comissioned work for WWD magazine (Safe Harbor, Artist series)</a></li>

                     <div className="h-2"></div>

                     <label className="block leading-5">July / August 2016 – Bozel, France</label>
                     <li className="leading-5">La Tour Sarrazine group show</li>
                  </ul>

                  <div className="h-10"></div>

                  <h2 className="text-xl font-bold text-center">EDUCATION</h2>
                  <div className="h-1" />
                  <ul className="w-full">
                     <label className="block leading-5">2014 / 2016 – Paris, France</label>
                     <li className="leading-5">MAA - Visual Communication - Creapole ESDI</li>

                     <div className="h-2"></div>

                     <label className="block leading-5">2011 / 2014 – Paris, France</label>
                     <li className="leading-5">BAA - Visual Communication - Creapole ESDI</li>
                  </ul>

                  <div className="h-10"></div>

                  <Social />

                  <div className="h-0 md:h-8"></div>
               </div>

            </div>

         </div>

         <div className="w-full md:hidden flex-col-center">

            <div className="h-32 md:h-4"></div>

            <div className="w-full flex flex-col justify-center items-center z-20" style={{ fontWeight: 450 }}>

               <p className="flex-none text-lg text-justify leading-6 md:w-96 px-10 md:px-4">Born in March 1991, Gaspard Deshusses is an independent artist, painter and graphic designer native of the French Alps. Fascinated with colored shapes, he disassembles, associates and searches for a balance between abstract and figurative appearances, as well as their very limits.<br /><br />In 2015, while he was still a graphic design student, he started frantically tearing up all the fashion magazines he could find, and turned them into abstract and colored collage tableaux. He created dozens of works using this process throughout the following years. Gaspard obtained a Masters of Applied Arts in 2016, graduating with honors, and learned to differentiate art and design over time. The pursuit of his research endeavor then brought him to watercolor and oil paint. This changeover was a way for him to enlarge his formats, work on his own colors, and make his graphic approach evolve while including a stronger figurative dimension within his work.<br /><br />Buoyed by religious and fashion iconography as much as prehistoric art, Gaspard aims to create wild and sensual moods through methodical and structured compositions. He works by associating shapes and colors through textures, shades, layers, strokes and swathes to create a scaffold of edged representations. This orderly chaos is his chosen way of illustrating a paradox between the contrast that comes with confrontation and the harmony which eventually arises from any kind of coexistence.<br /><br />After living several years in Paris, New York City and Brussels, Gaspard now lives and works in the French Alps. Based at the heart of the mountains where his studio is located, he takes a carnal and spiritual look at his real and digital environment. Through a profuse production, his work assumes a raw and experimental approach, driven by an obsessive research endeavor.</p>

               <div className="flex-none w-10 h-10"></div>

               <div className="flex-none leading-6 md:w-96 px-10 md:px-4 h-full flex-col-center">

                  <div className="h-8"></div>

                  <h2 className="text-xl font-bold">CONTRIBUTION</h2>
                  <ul className="w-full">

                     <label className="block leading-5">October / November 2021 — Paris, France</label>
                     <li className="leading-5"><a href="https://fb.me/e/33tgdFNbV" target="_blank" rel="noreferrer" className="hover:opacity-0"><span className="fraktur">Witches Are Saints</span> solo show @ l’Autre Thé tea shop</a></li>

                     <label className="block leading-5">July 2021 – Paris, France</label>
                     <li className="leading-5"><a href="https://www.facebook.com/events/774543956503476/" target="_blank" rel="noreferrer" className="hover:opacity-0">Floréal Belleville SUPERPAUSE ( ϟ ll ) group show</a></li>

                     <div className="h-2"></div>

                     <label className="block leading-5">March 2021 – Paris, France</label>
                     <li className="leading-5"><a href="https://www.cocktail-brut.com/journal/2021/2/24/46-gaspard-deshusses" target="_blank" rel="noreferrer" className="hover:opacity-0">Interview for <span class="hidden md:inline-block">the fashion boutique</span> Cocktail Brut</a></li>

                     <div className="h-2"></div>

                     <label className="block leading-5">April 2020 – Los Angeles, USA</label>
                     <li className="leading-5"><a href="https://wwd.com/fashion-news/fashion-features/gallery/safe-harbor-artist-series-day-one-1203625633/wwd-artists-respond-to-the-meaning-of-home-amidst-coronavirus-lockdowns-3/" target="_blank" rel="noreferrer" className="hover:opacity-0">Comissioned work for WWD magazine (Safe Harbor, Artist series)</a></li>

                     <div className="h-2"></div>

                     <label className="block leading-5">July / August 2016 – Bozel, France</label>
                     <li className="leading-5">La Tour Sarrazine group show</li>
                  </ul>

                  <div className="h-8"></div>

                  <h2 className="text-xl font-bold">EDUCATION</h2>
                  <ul className="w-full">
                     <label className="block leading-5">2014 / 2016 – Paris, France</label>
                     <li className="leading-5">MAA - Visual Communication <br /> Creapole ESDI</li>

                     <div className="h-2"></div>

                     <label className="block leading-5">2011 / 2014 – Paris, France</label>
                     <li className="leading-5">BAA - Visual Communication <br /> Creapole ESDI</li>
                  </ul>

                  <div className="h-8"></div>

                  <Social />

               </div>

            </div>

            <div className="h-32 md:h-0"></div>

         </div>

      </>
   );
}



