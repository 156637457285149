import React, { useState, useEffect } from 'react';
import '../../styles/main.css';
import TRUCK from '../../logos/merch/TRUCK.jpg'

export default function PosterBus(prop) {

   const [truckLoaded, setTruckLoaded] = useState(false);
   const [posterLoaded, setPosterLoaded] = useState(false);


   return (
      <div className="flex-grow-0 flex-shrink-0" >
         <div className="h-8"></div>
         <div className="relative flex flex-col items-end">

            <img
               height={100}
               src={TRUCK} alt="Truck"
               style={{ width: prop.busWidth + "rem" }}
               className={(truckLoaded && posterLoaded)
                  ? "object-contain"
                  : "opacity-0 object-contain"}
               onLoad={() => setTruckLoaded(true)}
            />

            <img
               id={"image" + prop.poster._id}
               src={"https://gasparddeshusses.com/images/artworks/" + prop.poster.artworkIdentifier + ".jpg"}
               alt={prop.poster.artworkIdentifier}
               className={(truckLoaded && posterLoaded)
                  ? "absolute m-auto object-contain"
                  : "opacity-0 absolute m-auto object-contain"}
               style={{ width: (prop.busWidth) / 4 + "rem", top: "40%", bottom: "60%", right: "20%", maxHeight: "35%" }}
               onLoad={() => setPosterLoaded(true)}
            />

         </div>

         <div className="h-8"></div>

      </div>

   );

}







/*import React, { useState } from 'react';
import '../../../styles/main.css';
import TRUCK from '../../../logos/merch/TRUCK.jpg'
import LazyLoad from 'react-lazyload'

export default function PosterBus(props) {

   const [prop] = useState(props);

   return (
      <>

            <div className="relative flex flex-col items-end">

               <img className="object-contain" src={TRUCK} alt="Truck" style={{ width: prop.busWidth + "rem" }}></img>

               <img id={"image" + prop.poster._id} src={"https://gasparddeshusses.com/images/artworks/" + prop.poster.posterIdentifier + ".jpg"} alt={prop.poster.posterIdentifier} className="absolute m-auto object-contain" style={{ width: (prop.busWidth) / 4 + "rem", top: "40%", bottom: "60%", right: "20%", maxHeight: "35%" }} />

            </div>
      </>
   );

}*/


/*
transform -rotate-90
   render() {
    const { title, img, type, price, inCart } = this.props.product;
    return (
      <div className="m-4">
        <div onClick={() => console.log('you clicked on the image container')}>
          <Link to="/details">
            <img src={img} alt="print" className="object-contain h-64" />
          </Link>

          <h1 className="">{type} - {title}</h1>
          <h2 className="">{price} euros</h2>

          <button className="font-bold text-center border-2 border-black px-4 py-1 focus:outline-none focus:bg-black focus:text-white" disabled={inCart ? true : false} onClick={() => console.log("Added to the cart")}>
            {inCart ? (<p disabled> IN CART</p>) : (<p className="hover:text-white">ADD TO CART</p>)}
          </button>
        </div>

      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id:PropTypes.number,
    img:PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    inCart: PropTypes.bool
  }).isRequired
} */